import React from 'react';
import { Link } from 'react-router-dom';
import session_expired_icon from '../../../img/session_expired_icon.png';

// CSS Import
import '../../../css/layout/modals/otpSessionTimeout.css';

const OtpSessionTimeOut = () => {
  return (
    <div className='modal-div-ppt fixed inset-0 z-50 flex items-center justify-center backdrop-blur-md'>
      <div className='bg-white rounded-lg p-8 w-[390px] h-[462px] shadow-lg text-center'>
        <div className='flex items-center justify-center mb-6'>
          {/* Replace 'your-icon.svg' with your actual icon */}
          <img
            src={session_expired_icon}
            alt='Icon'
            className='session-erpire-icon'
          />
        </div>
        <h2 className='session-primary-text'>Session Expired !</h2>
        <p className='session-secondary-text'>
          Your OTP session has expired. To proceed, kindly register again. Click
          Register to go to the registration page.
        </p>
        <div className='flex justify-center'>
          <Link
            to='/register'
            className='session-button-link py-2 px-4 border border-transparentfocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider hover:no-underline'
          >
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OtpSessionTimeOut;
