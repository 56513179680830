import React from 'react';

import full_star from '../../img/1.png';
import half_star from '../../img/0.5.png';
import empty_star from '../../img/0.png';

const getStar = (value) => {
  switch (value) {
    case 0:
      return empty_star;
    case 50:
      return half_star;
    case 100:
      return full_star;
    default:
      return empty_star;
  }
};

const getStars = (value) => {
  // switch (parseFloat(value)) {
  //   case 0:
  //     return [0, 0, 0, 0, 0];
  //   case 0.5:
  //     return [50, 0, 0, 0, 0];
  //   case 1:
  //     return [100, 0, 0, 0, 0];
  //   case 1.5:
  //     return [100, 50, 0, 0, 0];
  //   case 2:
  //     return [100, 100, 0, 0, 0];
  //   case 2.5:
  //     return [100, 100, 50, 0, 0];
  //   case 3:
  //     return [100, 100, 100, 0, 0];
  //   case 3.5:
  //     return [100, 100, 100, 50, 0];
  //   case 4:
  //     return [100, 100, 100, 100, 0];
  //   case 4.5:
  //     return [100, 100, 100, 100, 50];
  //   case 5:
  //     return [100, 100, 100, 100, 100];
  //   default:
  //     return [100, 100, 100, 100, 100];
  // }
  const stars = [];
  const [whole, part] = parseFloat(value).toString().split('.');
  for (let i = 0; i < whole; i++) stars.push(100);
  if (part) stars.push(50);
  for (let i = whole; i < (part ? 4 : 5); i++) stars.push(0);

  return stars;
};

const Rating = ({ value }) => {
  // console.log('rating value', value);
  return (
    <div className='flex justify-center'>
      {getStars(value).map((value) => (
        <img src={getStar(value)} width={20} alt='star_rating'></img>
      ))}
    </div>
  );
};

export default Rating;
