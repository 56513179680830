/* This example requires Tailwind CSS v2.0+ */
import { useEffect, Fragment, useState } from 'react';

import { Link, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LockClosedIcon } from '@heroicons/react/solid';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import Loader from '../loader/Loader';
import SignInAccess from '../layout/SignInAccess';
import Rating from '../layout/Rating';
import { getProductList } from '../../actions/products';
import ProductCard from '../layout/cards/ProductCard';
import { selectedProductUrls } from '../../actions/products';
import Navbar from '../layout/Navbar';
// import ProductCard from '../layout/cards/ProductCard';

// CSS
import '../../css/productsList.css';

// Images
import products_icon from '../../img/products_icon.png';
import logo_sm_color from '../../img/rn_logo_color_1.png';
import CSAT from '../../img/CSAT.png';

// Data
const data = require('../../data/amazon.json');
const prodList = require('../../data/productListReviewNow.json');

// const shops = ['amazon', 'flipkart'];

let ProductsList = ({
  auth: { isAuthenticated, user },
  products: { isProductLoaded, search_value, vendor_name },
  setAlert,
  getProductList,
  selectedProductUrls,
}) => {
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState();
  const [amazonItems, setAmazonItems] = useState();
  const [flipkartItems, setFlipkartItems] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [activeVendor, setActiveVendor] = useState(
    vendor_name.length === 1 ? vendor_name[0] : vendor_name[0]
  );

  const [products, setProducts] = useState([
    {
      id: 1,
      name: 'Product 1',
      verifiedBuyers: 50,
      category: 'Category 1',
      comments: 10,
      rating: 4.5,
      selected: false,
    },
    {
      id: 2,
      name: 'Product 2',
      verifiedBuyers: 100,
      category: 'Category 2',
      comments: 20,
      rating: 4.2,
      selected: false,
    },
    // add more products as needed
  ]);
  const [currentPage, setCurrentPage] = useState(1);

  console.log('vendor_name', vendor_name);

  useEffect(() => {
    // console.log('search_value', search_value);
    getProductList(search_value, vendor_name).then((res) => {
      setRes(res.data);
      setAmazonItems(res.data.amazon);
      setFlipkartItems(res.data.flipkart);
      setLoading(true);
    });
  }, []);

  const getPaginatedItems = (items) => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return items.slice(startIndex, endIndex);
  };

  const totalAmazonItems = amazonItems ? amazonItems.length : 0;
  const totalFlipkartItems = flipkartItems ? flipkartItems.length : 0;
  const totalProducts = totalAmazonItems + totalFlipkartItems;

  const totalPages = Math.ceil(
    (activeVendor === 'amazon' ? totalAmazonItems : totalFlipkartItems) / 10
  );

  // Checkbox Change Handler
  const onCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      console.log(e.target.value);
      setSelectedProducts([...selectedProducts, value]);
    } else {
      setSelectedProducts(selectedProducts.filter((e) => e !== value));
      // setSelectAll(!selectAll);
    }
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const removeSelected = () => {
    console.log('Remove Selected');
    setSelectedProducts([]);
  };

  // const selectAll = () => {
  //   let tempArr = [];
  //   Object.keys(res).map((key) => {
  //     res[key].map((obj) => {
  //       console.log('obj', obj);
  //       tempArr.push(
  //         `${obj['Product URL']}_productName/${obj['Product Name']}_price/${obj['Price']}_customerRating/${obj['Customer Rating']}`
  //       );
  //     });
  //   });
  //   setSelectedProducts([...tempArr]);
  //   // console.log('temp Arr', tempArr);
  // };

  const handleCheckboxChange = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, selected: !product.selected }
          : product
      )
    );
  };

  const handleSelectAll = () => {
    let tempArr = [];
    Object.keys(res).map((key) => {
      res[key].map((obj) => {
        console.log('obj', obj);
        tempArr.push(
          `${obj['Product URL']}_productName/${obj['Product Name']}_price/${obj['Price']}_customerRating/${obj['Customer Rating']}`
        );
      });
    });
    setSelectedProducts([...tempArr]);
    // console.log('temp Arr', tempArr);

    if (selectAll) {
      setSelectedProducts([]);
    }
    setSelectAll(!selectAll);
  };

  if (isProductLoaded) {
    console.log('res', res);
    console.log('amazon Items', amazonItems);
    console.log('flipkart Items', flipkartItems);
  }

  const selectTemplates = () => {
    // console.log(selectedProducts);
    selectedProductUrls(selectedProducts);
    setNextPage(true);
  };
  console.log('nextPage-1', nextPage);
  if (nextPage) {
    return <Navigate to='/reports-power-bi' />;
  }

  // const remainingCards = (data) => {
  //   const result = [];
  //   for (let i = 4; i < data.length; i++) {
  //     if (data.length - i >= 4) {
  //       result.push(
  //         <div className='carousel-item'>
  //           <div className='flex flex-wrap -mx-4'>
  //             <ProductCard
  //               index_val={i}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 1}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 2}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 3}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //           </div>
  //         </div>
  //       );
  //       i = i + 3;
  //     } else if (data.length - i >= 3) {
  //       result.push(
  //         <div className='carousel-item'>
  //           <div className='flex flex-wrap -mx-4'>
  //             <ProductCard
  //               index_val={i}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 1}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 2}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //           </div>
  //         </div>
  //       );
  //       i = i + 2;
  //     } else if (data.length - i >= 2) {
  //       result.push(
  //         <div className='carousel-item'>
  //           <div className='flex flex-wrap -mx-4'>
  //             <ProductCard
  //               index_val={i}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //             <ProductCard
  //               index_val={i + 1}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //           </div>
  //         </div>
  //       );
  //       i = i + 1;
  //     } else if (data.length - i >= 1) {
  //       result.push(
  //         <div className='carousel-item'>
  //           <div className='flex flex-wrap -mx-4'>
  //             <ProductCard
  //               index_val={i}
  //               data={data}
  //               onCheck={onCheck}
  //               selectedProducts={selectedProducts}
  //             />
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   return result;
  // };

  console.log('isProductLoaded', isProductLoaded);
  console.log('selected products', selectedProducts);
  if (isAuthenticated && user) {
    return (
      <Fragment>
        {!loading ? (
          <Loader />
        ) : (
          // <div className='min-h-full'>
          //   <main>
          //     <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
          //       <div className='flex justify-between'>
          //         <div>
          //           <Link to='/dashboard' style={{ textDecoration: 'none' }}>
          //             <i class='fa-solid fa-circle-arrow-left'></i> Search your
          //             Products
          //           </Link>
          //         </div>
          //         <div className='flex'>
          //           <div>
          //             <button
          //               type='button'
          //               className='text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-2.5 py-1.5 text-center'
          //               onClick={selectAll}
          //             >
          //               Select All
          //             </button>
          //           </div>
          //           <div>
          //             <button
          //               type='button'
          //               className='text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-2.5 py-1.5 text-center ml-3'
          //               onClick={removeSelected}
          //             >
          //               Remove Selection
          //             </button>
          //           </div>
          //           <div className='form-check form-check-inline'>
          //             <input
          //               id='flowbite'
          //               aria-describedby='flowbite'
          //               type='checkbox'
          //               className='bg-gray-50 border-blue-400 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded ml-3'
          //               checked={selectedProducts.length}
          //             />
          //             <label
          //               for='flowbite'
          //               className='form-check-label inline-block text-sm ml-2 font-medium text-gray-900'
          //             >
          //               {selectedProducts.length} Products Selected
          //             </label>
          //           </div>
          //         </div>
          //       </div>
          //       <div>
          //         {loading ? (
          //           Object.keys(res).map((key) => {
          //             return (
          //               <fieldset className='border p-2'>
          //                 <legend className='float-none w-auto p-2'>
          //                   {key.charAt(0).toUpperCase() + key.slice(1)}
          //                 </legend>
          //                 <div className='container mx-auto'>
          //                   <div
          //                     id={`${key}-carousel`}
          //                     className='carousel slide carousel-multi-item'
          //                     data-ride='carousel'
          //                     data-interval='false'
          //                   >
          //                     <div className='carousel-inner' role='listbox'>
          //                       <div className='carousel-item active'>
          //                         <div className='flex flex-wrap -mx-4'>
          //                           <ProductCard
          //                             index_val={0}
          //                             data={res[key]}
          //                             onCheck={onCheck}
          //                             selectedProducts={selectedProducts}
          //                           />
          //                           <ProductCard
          //                             index_val={1}
          //                             data={res[key]}
          //                             onCheck={onCheck}
          //                             selectedProducts={selectedProducts}
          //                           />
          //                           <ProductCard
          //                             index_val={2}
          //                             data={res[key]}
          //                             onCheck={onCheck}
          //                             selectedProducts={selectedProducts}
          //                           />
          //                           <ProductCard
          //                             index_val={3}
          //                             data={res[key]}
          //                             onCheck={onCheck}
          //                             selectedProducts={selectedProducts}
          //                           />
          //                         </div>
          //                       </div>

          //                       {remainingCards(res[key])}
          //                     </div>
          //                     <div className='flex justify-center py-4'>
          //                       <a
          //                         className='btn-floating px-1'
          //                         href={`#${key}-carousel`}
          //                         data-slide='prev'
          //                       >
          //                         <i class='fa-solid fa-2xl fa-circle-arrow-left'></i>
          //                       </a>
          //                       <a
          //                         className='btn-floating px-1'
          //                         href={`#${key}-carousel`}
          //                         data-slide='next'
          //                       >
          //                         <i class='fa-solid fa-2xl fa-circle-arrow-right'></i>
          //                       </a>
          //                     </div>
          //                   </div>
          //                 </div>
          //               </fieldset>
          //             );
          //           })
          //         ) : (
          //           <></>
          //         )}
          //       </div>
          //       <div className='flex justify-end'>
          //         <button
          //           onClick={selectTemplates}
          //           type='button'
          //           className='btn-sm text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4'
          //         >
          //           Next
          //         </button>
          //       </div>
          //     </div>
          //   </main>
          // </div>

          <div className='background-prod-list min-h-full'>
            <Navbar></Navbar>
            <div className='pb-4 mt-5 pl-5'>
              <Link
                to='/dashboard'
                className='ml-2 hover:no-underline back-link-button hover:text-primary_1-10'
              >
                <i class='fa-solid fa-chevron-left mr-1'></i> Search Product
              </Link>
            </div>
            <div className='flex items-center justify-between px-4'>
              <div className='flex items-center ml-4'>
                <h1 className='product-list-heading'>Product List</h1>
                {vendor_name.includes('amazon') ||
                vendor_name.includes('flipkart') ? (
                  <div className='flex items-center space-x-4 ml-4'>
                    {vendor_name.includes('amazon') && (
                      <button
                        className={`vendor-button ${
                          activeVendor === 'amazon'
                            ? 'button-bg-gradient text-white focus:outline-none'
                            : 'focus:outline-none'
                        }`}
                        onClick={() => setActiveVendor('amazon')}
                      >
                        Amazon
                      </button>
                    )}
                    {vendor_name.includes('flipkart') && (
                      <button
                        className={`vendor-button ${
                          activeVendor === 'flipkart'
                            ? 'button-bg-gradient text-white focus:outline-none'
                            : 'focus:outline-none'
                        }`}
                        onClick={() => setActiveVendor('flipkart')}
                      >
                        Flipkart
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
              <div className='flex items-center space-x-4 mr-4 pr-2'>
                <p className='selected-products flex items-center'>
                  <img
                    src={products_icon}
                    alt='Products Icon'
                    className='w-3 h-3 mr-2'
                  />
                  Selected Product:{' '}
                  <span className='selected-products-value'>
                    {selectedProducts.length}
                  </span>
                </p>
                <p className='total-products'>
                  Total Products:{' '}
                  <span className='total-products-value'>{totalProducts}</span>
                </p>
                {/* <p className='total-products'>
                  Total Products:{' '}
                  <span className='total-products-value'>{totalProducts}</span>
                </p> */}
                <button
                  className='generate-report-button focus:outline-none hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                  onClick={selectTemplates}
                  disabled={selectedProducts.length === 0}
                >
                  <i class='fa-solid fa-file-signature'></i> Generate Report
                </button>
              </div>
            </div>
            <div className='flex items-center justify-center mt-3 pb-4'>
              <table className='table-auto w-11/12 rounded-lg overflow-hidden bg-white shadow-md'>
                <thead className='border-b border-gray-400'>
                  <tr className='text-gray-900 uppercase text-sm leading-normal'>
                    <th className='py-3 px-4 text-center'>
                      <div className='flex items-center'>
                        <input
                          type='checkbox'
                          checked={selectedProducts.length === totalProducts}
                          onChange={handleSelectAll}
                          // onChange={selectAll}
                          className='rounded-sm'
                        />
                        <span className='ml-2 font-medium'>All</span>
                      </div>
                    </th>
                    <th className='py-3 px-4 text-center font-medium'>
                      Product Name
                    </th>
                    <th className='py-3 px-4 text-center font-medium'>
                      Verified Buyers
                    </th>
                    <th className='py-3 px-4 text-center font-medium'>
                      Category
                    </th>
                    <th className='py-3 px-4 text-center font-medium'>
                      Comments
                    </th>
                    <th className='py-3 px-4 text-center font-medium'>
                      Rating
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody className='text-gray-600 text-sm font-light'>
                    {getPaginatedItems(
                      activeVendor === 'amazon' ? amazonItems : flipkartItems
                    ).map((product) => (
                      <tr
                        key={product.id}
                        className={`border-b border-gray-200 hover:bg-gray-100 ${
                          product.selected ? 'bg-blue-100' : ''
                        }`}
                      >
                        <td className='py-2 px-4 text-center'>
                          <input
                            type='checkbox'
                            // checked={selectAll || product.selected}
                            checked={selectedProducts.includes(
                              `${product['Product URL']}_productName/${product['Product Name']}_price/${product['Price']}_customerRating/${product['Customer Rating']}`
                            )}
                            // onChange={() =>
                            //   handleCheckboxChange(product.id)
                            // }
                            onChange={(e) => {
                              onCheck(e);
                            }}
                            className='rounded-sm cursor-not-allowed'
                            value={`${product['Product URL']}_productName/${product['Product Name']}_price/${product['Price']}_customerRating/${product['Customer Rating']}`}
                            disabled
                          />
                        </td>
                        <td className='py-2 px-4 mx-5 flex  items-center'>
                          <div className='relative w-8 h-8 my-2'>
                            <img
                              src={product['Product Image']}
                              alt='Prod img'
                              className='absolute inset-0 object-contain'
                            />
                          </div>
                          {/* {product.name} */}
                          <div className='pl-4'>
                            {product['Product Name'].slice(0, 80) +
                              (product['Product Name'].length > 80
                                ? '...'
                                : '')}
                          </div>
                        </td>
                        <td className='py-2 px-4 text-center'>
                          {product.verifiedBuyers}
                        </td>
                        <td className='py-2 px-4 text-center'>
                          {product.category}
                        </td>
                        <td className='py-2 px-4 text-center'>
                          {product.comments}
                        </td>
                        <td className='py-2 px-4 text-center'>
                          {/* {product.rating} */}
                          {product['Customer Rating']}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
            </div>

            <div className='w-full flex justify-center pt-1 pb-5'>
              <div className='w-1/4 bg-white text-black items-center flex justify-between rounded-full py-1 shadow-md'>
                <button
                  className='prev-button py-1 px-2 focus:outline-none'
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <i class='fa-solid fa-chevron-left pr-1 pl-1'></i>PREV
                </button>
                <p className='page-count'>
                  {currentPage} | {totalPages}
                </p>
                <button
                  className=' next-button py-1 px-2 focus:outline-none'
                  onClick={handleNextPageClick}
                  disabled={currentPage === totalPages}
                >
                  NEXT<i class='fa-solid fa-chevron-right pl-1'></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{!isProductLoaded ? <Loader /> : null}</>;
  }
};

ProductsList.propTypes = {
  getProductList: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.products,
});

export default connect(mapStateToProps, {
  setAlert,
  getProductList,
  selectedProductUrls,
})(ProductsList);
