import axios from 'axios';
import { setAlert } from './alert';
import {
  PRODUCT_LIST_FAILED,
  PRODUCT_LIST_SUCCESS,
  SEARCH_VALUE,
} from './types';

// GET PRODUCTS LIST
export const getProductList = (input_val, shop) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    input_val,
    no_of_pages: '1',
    shop,
  });

  try {
    const res = await axios.post(
      `https://productlistingapiv1.azurewebsites.net/api/product_list/`,
      body,
      config
    );

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }

    dispatch({
      type: PRODUCT_LIST_FAILED,
    });
  }
};

// GET PROD LIST 2
export const getProductList2 =
  (search_value, vendor_name) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    console.log('vendor name asdjakskd', vendor_name);

    const body = JSON.stringify({
      search_value,
      no_of_pages: '1',
      vendor_name,
    });

    console.log('test value', search_value);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/review-now/api/earphones`,
        body,
        config
      );

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PRODUCT_LIST_FAILED,
      });
    }
  };

// SET SEARCH VALUE
export const searchProduct =
  (searchText, checkedValues, category) => async (dispatch) => {
    try {
      const data = {
        search_value: searchText,
        vendor_name: checkedValues,
        category,
      };

      dispatch({
        type: SEARCH_VALUE,
        payload: data,
      });
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, 'danger'));
        });
      }
    }
  };

// Send selected products to API
export const selectedProductUrls =
  (selectedProducts, search_value, vendor_name) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const body = JSON.stringify({
        selectedProducts,
        search_value,
        vendor_name,
      });

      console.log('body', body);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/review-now/api/save-selected-products`,
        body,
        config
      );

      // dispatch({
      //   type: SELECTED_PROD_SUCCESS,
      //   payload: res.data,
      // });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, 'danger'));
        });
      }

      // dispatch({
      //   type: SELECTED_PROD_FAIL,
      //   payload: res.data,
      // });
    }
  };

export const Masala_and_Spices = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/refresh-report-masala-and-spices`,
      config
    );

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
  }
};

export const cosmetics = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/refresh-report-cosmetics`,
      config
    );

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
  }
};

export const audio = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/refresh-report-audio`,
      config
    );

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
  }
};
