/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import axios from 'axios'; // You can use axios for API requests

import SignInAccess from '../layout/SignInAccess';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import Loader from '../loader/Loader';
import { searchProduct } from '../../actions/products';

// Import Data
import prodData from '../../data/scrappedProducts.json';

// Import Components
import Navbar from '../layout/Navbar';
import ProductCardSearchPage from '../layout/cards/ProductCardSearchPage';
import PlatformCheckbox from '../layout/dropdowns/PlatformCheckbox';
import CategoriesDropdown from '../layout/dropdowns/CategoriesDropdown';
import ProductKeyMetricsModal from '../layout/modals/ProductKeyMetricsModal';
import SearchInputDropDown from '../layout/dropdowns/SearchInputDropDown';

import '../../css/searchPage.css';

// Import Images
import logo_sm_color from '../../img/rn_logo_color_1.png';
import search_button_icon_2 from '../../img/search_button_icon_2.png';
import product_icon from '../../img/product_icon.png';
import platform_icon from '../../img/platform_icon.png';
import category_icon from '../../img/category_icon.png';

// Import data
import category from '../../data/category.json';
import searchInputValues from '../../data/searchInputValues.json';
// import platformValues from '../../data/platforms.json';
import commonPlatform from '../../data/platformList/commonPlatform.json';
import Loader2 from '../loader/Loader2';

let Dashboard = ({
  auth: { isAuthenticated, user },
  setAlert,
  searchProduct,
}) => {
  const [productPage, setProductPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('earphones');
  const [checkedValues, setCheckedValues] = useState([]);
  // const [category, setCategory] = useState('electronics');
  const [selected, setSelected] = useState(category[0]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [randomObjects, setRandomObjects] = useState([]);
  const [selectedInputText, setSelectedInputText] = useState(
    searchInputValues[0]
  );
  const [selectedplatform, setSelectedplatform] = useState([]);
  const [platformValues, setPlatformValues] = useState(null);
  const [loadingList, setLoadingList] = useState(false);

  console.log('selectedplatform', selectedplatform.value);

  // console.log('selectedInputText.value', selectedInputText.value);
  // console.log('selectedInputText.platform', selectedInputText.platform);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the data from the JSON file
        const objects = prodData;

        // Shuffle the array
        const shuffled = objects.sort(() => 0.5 - Math.random());

        // Get sub-array of first 4 elements after shuffling
        const selected = shuffled.slice(0, 4);

        // Set the state of randomObjects
        setRandomObjects(selected);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPlatformValues = async () => {
      try {
        setLoadingList(true);
        // Make an API call to fetch platform-specific values
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/review-now/api/platform-master-list?product_name=${selectedInputText.platform}`
        );

        if (response.data) {
          // Set the platformValues state with the fetched data
          setPlatformValues(response.data.records[0].platform);
          // Filter the initially selected platforms (selected: 1)
          const initialSelectedPlatforms =
            response.data.records[0].platform.filter(
              (platform) => platform.selected === 1
            );

          setSelectedplatform([]);
          setLoadingList(false);
          // setSelectedplatform(initialSelectedPlatforms || null);
          // console.log('platformValues gggg', platformValues);
          // console.log('selectedplatform gggg', selectedplatform);
        }
      } catch (error) {
        console.error(error);
        // Handle the error as needed
      }
    };

    fetchPlatformValues();
  }, [selectedInputText.platform]);

  // Search Text Change Handler
  const onChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  // Checkbox Change Handler
  const onCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // console.log(e.target.value);
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((e) => e !== value));
    }
  };

  // Category Change Handler
  // const onCategory = (e) => {
  //   e.preventDefault();
  //   setCategory(e.target.value);
  // };

  // Search Button Handler
  const onSubmit = async (e) => {
    console.log('before prevent', selectedplatform);
    e.preventDefault();
    // setCategory(selected.value);
    if (!selectedplatform.length) {
      return window.alert('Please select atleast one Platform from checkbox');
    }
    console.log('after prevent', selectedplatform);

    // Convert the array to a JSON string
    const jsonString = JSON.stringify(selectedplatform);

    localStorage.setItem('vendor', jsonString);

    localStorage.setItem('search_value', selectedInputText.value);

    // searchProduct(searchValue, checkedValues, selected.value); // category to selected.value
    searchProduct(searchValue, selectedplatform, searchInputValues.value); // category to selected.value

    setProductPage(true);

    console.log(selectedplatform);
  };
  if (productPage) {
    // return <Navigate to='/products-list' />;
    return <Navigate to='/prod-list' />;
  }
  if (isAuthenticated && user) {
    console.log('hello', selectedplatform, searchValue, category);
    console.log('cat', category);
    console.log('randomObjects', randomObjects);
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className='relative gradient-background text-center'>
            <Navbar></Navbar>
            {loadingList && <Loader2 loading={loadingList} />}{' '}
            {/* Render the loader when loading is true */}
            <div className='pt-12'>
              <h1 className='search-page-heading'>Make Your Reviews Count</h1>
              <p className='search-page-sub-heading'>
                Reviews tell a story. We help in telling yours
              </p>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className='search-box-container rounded-full mx-auto mt-6 h-12 w-2/3 flex items-center shadow-md'>
                  <div className='flex-1 flex items-center w-auto justify-center h-full border-gray-900'>
                    <div className='flex items-center h-full '>
                      <img
                        src={product_icon}
                        alt='icon'
                        className='product-icon mr-4'
                      />
                      <SearchInputDropDown
                        searchInputValues={searchInputValues}
                        selected={selectedInputText}
                        setSelected={setSelectedInputText}
                      />
                    </div>
                  </div>

                  <div className='platform-main-div flex-1 h-3/4 flex items-center justify-center'>
                    <div className='flex items-center'>
                      <img
                        src={platform_icon}
                        alt='icon'
                        className='platform-icon mr-4'
                      />
                      {platformValues !== null ? (
                        <PlatformCheckbox
                          platformValues={platformValues}
                          selectedplatform={selectedplatform}
                          setSelectedplatform={setSelectedplatform}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* <div className='flex-1 h-full border-gray-900 flex justify-center'>
                    <div className='flex items-center h-full '>
                      <img
                        src={category_icon}
                        alt='icon'
                        className='category-icon'
                      />
                      <CategoriesDropdown
                        people={category}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </div>
                  </div> */}
                  <div className='w-14 h-full border-gray-900 flex items-center justify-center '>
                    <button type='submit' className='focus:outline-none'>
                      <img
                        src={search_button_icon_2}
                        alt='search-button'
                        className='h-10 w-10 mx-auto'
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='flex justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2'>
              {randomObjects.map((obj) => (
                <ProductCardSearchPage
                  price={'$50'}
                  brand={obj.brand}
                  prod_image={obj['image url']}
                  prod_name={obj['product name']}
                  setSelectedProduct={setSelectedProduct}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  bestSellerRank={obj.rank}
                  sentimentScore={obj['sentiment score']}
                  startRating={obj.rating}
                  verifiedReviews={obj['no of verified reviews']}
                  category={obj.category}
                  industry={obj.industry}
                />
              ))}
            </div>
            {showModal && (
              <ProductKeyMetricsModal
                selectedProduct={selectedProduct}
                // onClose={() => setShowModal(false)}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            )}
          </div>
        )}
      </>
    );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  // isRegistered: state.auth.isRegistered,
  // verified: state.auth.user.verified,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, searchProduct })(Dashboard);
