import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';

import SignInAccess from '../layout/SignInAccess';
import Loader from '../loader/Loader';

let SentimentAnalysis = ({ auth: { isAuthenticated, user }, setAlert }) => {
  const [sentimentType, setsentimentType] = useState('text');
  const [selectedFile, setSelectedFile] = useState('');
  const [formdata, setFormData] = useState({
    analysis_type: '',
    file: '',
    file_name: '',
    text: '',
    sentiment: 'Positive',
    sentiment_score: '93.5',
  });

  const [loading, setLoading] = useState(true);
  const [fileExt, setFileExt] = useState('');
  const allowedFileTypes = ['xlsx', 'csv', 'pdf', 'txt'];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Destructure formData
  const { analysis_type, file, file_name, text, sentiment, sentiment_score } =
    formdata;

  const textAnalysis = async () => {
    // e.preventDefault();
    setsentimentType('text');
    // console.log('on click', sentimentType);
  };
  const docAnalysis = async () => {
    // e.preventDefault();
    setsentimentType('doc');
    // console.log('on click', sentimentType);
  };

  const onFileChange = (e) => {
    const fileExtension = e.target.files[0].name.split('.').at(-1);
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ', '
        )}`
      );
      setSelectedFile('');
      setFileExt('');
      return false;
    } else {
      setSelectedFile(e.target.files[0]);
      setFileExt(fileExtension);
      console.log(fileExtension);
    }
  };

  console.log(selectedFile);

  const onChange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!allowedFileTypes.includes(fileExt)) {
      window.alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ', '
        )}`
      );
      return false;
    } else {
      console.log('sentimentType', sentimentType);
      console.log('File', selectedFile);
      console.log('text', text);
      console.log('file_name', selectedFile.name);

      const formData = new FormData();
      // Update the formData object
      formData.append('myFile', selectedFile, selectedFile.name);

      console.log('form_data', formData);
    }
  };

  if (isAuthenticated && user) {
    return (
      <>
        <div className='min-h-full'>
          <main>
            <div className='mt-2 flex justify-left'>
              <Link
                to='/converse-ai-products'
                style={{ textDecoration: 'none' }}
              >
                <i class='fa-solid fa-circle-arrow-left'></i> Select Services
              </Link>
            </div>
            <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
              <div className='px-4 py-6 sm:px-0'>
                <div className='w-full'>
                  <span className='text-xl font-medium'>
                    Sentiment Analysis
                  </span>
                </div>
                <div className='w-1/2 flex mt-3 ml-4 justify-left gap-4'>
                  <div className='items-center '>
                    <input
                      id='text_analysis_radio'
                      type='radio'
                      value=''
                      name='analysis_radio'
                      // onChange={(e) => onChange(e)}
                      onClick={textAnalysis}
                      defaultChecked={sentimentType === 'text'}
                      className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      for='default-radio-1'
                      className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      Text Sentiment Analysis
                    </label>
                  </div>
                  <div className='items-center'>
                    <input
                      id='doc_analysis_radio'
                      type='radio'
                      value=''
                      name='analysis_radio'
                      // onChange={(e) => docAnalysis(e)}
                      onClick={docAnalysis}
                      defaultChecked={sentimentType === 'doc'}
                      className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      for='default-radio-2'
                      className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      Document Sentiment Analysis
                    </label>
                  </div>
                </div>
                <div className='w-full flex justify-center'>
                  <div className='w-1/2'>
                    <div className='w-full'>
                      <textarea
                        className='mt-4 py-1 px-1 ml-4 disabled:cursor-not-allowed hover:disabled:cursor-not-allowed'
                        rows='10'
                        cols='60'
                        name='text'
                        id='text'
                        value={text}
                        placeholder='Place your text here...'
                        disabled={sentimentType === 'doc'}
                        onChange={(e) => onChange(e)}
                      ></textarea>
                    </div>
                    {/* <div className=''> */}
                    <div className='w-full flex mt-3'>
                      {/* <label
                          className='mt-1 py-1 px-1 ml-4 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                          for='file_input'
                        >
                          Upload file
                        </label> */}
                      <input
                        className='mt-1  ml-4 mr-4 block w-full text-sm text-grey-500
                        file:mr-5 file:py-2 file:px-6
                        file:rounded-lg file:border-0 border
                        file:text-sm file:font-medium
                        file:bg-blue-50 file:text-blue-700
                        hover:file:cursor-pointer 
                         disabled:cursor-not-allowed hover:file:disabled:cursor-not-allowed'
                        aria-describedby='file_input_help'
                        id='file_input'
                        type='file'
                        disabled={sentimentType === 'text'}
                        onChange={(e) => onFileChange(e)}
                      />
                    </div>
                    <div className='w-full flex mt-0 ml-4 text-xs text-gray-500 dark:text-gray-300'>
                      XLSX, CSV, PDF OR TXT
                    </div>
                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={(e) => onSubmit(e)}
                        className='mt-3 ml-4 text-white bg-blue-10 hover:bg-blue-20 focus:ring-2 focus:ring-blue-10 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                      >
                        Check
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className='w-1/2'>
                    <fieldset className='border p-2 h-96'>
                      <legend className='float-none w-auto p-2'>Output</legend>
                      <div className='relative overflow-hidden h-full'>
                        <div className='pt-2 abosulute'>
                          <span className='font-medium pl-5'>Sentiment : </span>{' '}
                          <span>{sentiment}</span>
                        </div>
                        <div className='pt-5'>
                          <span className='font-medium pl-5'>
                            Sentiment Score:{' '}
                          </span>{' '}
                          <span>{sentiment_score}</span>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );

    // return (
    //   <>

    //   </>
    // );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(SentimentAnalysis);
