import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../loader/Loader';
import axios from 'axios';

//CSS
import '../../../css/otherComponents/contactUs.css';

// Images
import review_now_logo_color from '../../../img/review_now_logo_color.png';
import { toast } from 'react-toastify';
import Loader2 from '../../loader/Loader2';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    mobile: '',
    company: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Destructure formData
  const { name, email, subject, mobile, company, message } = formData;

  const validateMobile = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, mobile, subject, company, message);

    // Validate mobile number
    if (!validateMobile(mobile)) {
      setErrors({ mobile: 'Mobile number must be a 10-digit number' });
      return;
    }

    setLoading(true);

    const sendMessage = await axios
      .post(
        `${process.env.REACT_APP_API_URI}/review-now/api/contact-us`,
        formData,
        {
          headers: {
            'Content-Type': 'Application/json',
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          toast.success('Message sent');
          // Reset form data
          setFormData({
            name: '',
            email: '',
            subject: '',
            mobile: '',
            company: '',
            message: '',
          });
          setErrors({});
        }
      })
      .catch((e) => {
        setLoading(false);
      });
    console.log('Success');
  };

  return (
    <>
      {loading ? (
        <Loader2 />
      ) : (
        <>
          <div className='flex justify-between items-center pt-1'>
            <div className='flex items-center pl-2'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 contact-text-logo hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='contact-about-link hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-contact-link pr-2 hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Additional Div */}
          <div className='flex justify-center items-center mt-8'>
            <div className='contact-main-div bg-white shadow-lg flex'>
              <div className='contact-column-1'>
                <h2 className='contact-contact-us-heading'>Contact Us</h2>
                <p className='mt-2 contact-contact-us-sub-text'>
                  Feel free to reach out for any queries or feedback.
                </p>
                <form
                  className='w-full'
                  onSubmit={(e) => onSubmit(e)}
                  method='POST'
                >
                  <input type='hidden' name='remember' defaultValue='true' />

                  <div className='flex flex-wrap mt-16'>
                    <div className='mb-4 w-1/2 pr-4'>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        value={name}
                        onChange={(e) => onChange(e)}
                        autoComplete='name'
                        required
                        placeholder='Full Name'
                        className='contact-input-field w-full border-b-2 border-gray-400 focus:ring-0 relative'
                      />
                    </div>
                    <div className='mb-4 w-1/2 pl-4'>
                      <input
                        id='company'
                        name='company'
                        type='text'
                        value={company}
                        onChange={(e) => onChange(e)}
                        autoComplete='name'
                        required
                        placeholder='Company'
                        className='contact-input-field w-full border-b-2 border-gray-400 focus:ring-0 relative'
                      />
                    </div>
                    <div className='mb-4 w-1/2 pr-4'>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        value={email}
                        onChange={(e) => onChange(e)}
                        autoComplete='email'
                        required
                        placeholder='Email'
                        className='contact-input-field w-full border-b-2 border-gray-400  focus:ring-0 relative'
                      />
                    </div>
                    <div className='mb-4 w-1/2 pl-4'>
                      <input
                        id='mobile'
                        name='mobile'
                        type='text'
                        value={mobile}
                        onChange={(e) => onChange(e)}
                        autoComplete='mobile'
                        required
                        placeholder='Mobile Number'
                        className={`contact-input-field w-full border-b-2 border-gray-400 focus:ring-0 relative ${
                          errors.mobile ? 'border-red-500 ' : ''
                        }`}
                      />
                      {errors.mobile && (
                        <p className='text-red-500 mt-1 text-xs'>
                          {errors.mobile}
                        </p>
                      )}
                    </div>
                    <div className='mb-4 w-full'>
                      <input
                        id='subject'
                        name='subject'
                        type='text'
                        value={subject}
                        onChange={(e) => onChange(e)}
                        autoComplete='subject'
                        required
                        placeholder='Subject'
                        className='contact-input-field w-full border-b-2 border-gray-400  focus:ring-0 relative'
                      />
                    </div>
                    <div className='mb-4 w-full'>
                      <textarea
                        id='message'
                        name='message'
                        type='text'
                        value={message}
                        onChange={(e) => onChange(e)}
                        autoComplete='message'
                        required
                        placeholder='Enter Message'
                        className='contact-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                      />
                    </div>
                  </div>
                  <div className='flex justify-end'>
                    <button
                      type='submit'
                      className='contact-button text-white py-1.5 px-4 rounded-lg w-1/3 mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider focus:outline-none'
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
              <div className='contact-column-2 p-4'>
                <h2 className='contact-address-heading-1'>Get in Touch</h2>

                <p className='contact-address-heading-2 mt-10'>Opening hours</p>
                <p className='contact-heading-sub-text mt-1 ml-1'>
                  Monday - Friday
                </p>
                <p className='contact-heading-sub-text ml-1'>
                  9 am to 6 pm IST
                </p>
                <p className='contact-heading-sub-text ml-1'>Weekend Closed</p>

                <p className='contact-address-heading-2 mt-10'>Support</p>
                <p className='contact-heading-sub-text mt-1 ml-1'>
                  +91 998 690 0450
                </p>
                <p className='contact-heading-sub-text ml-1'>
                  +91 961 860 1258
                </p>

                <p className='contact-address-heading-2 mt-10'>Address</p>
                <p className='contact-heading-sub-text mt-1 ml-1'>
                  Bangalore Office: 4th Floor, Gospaze, Aishwarya Complex Survey
                  Number 3/2 Sarjapur Main Road, Bellandur Gate Rd, Bengaluru,
                  Karnataka 560103
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactUs;
