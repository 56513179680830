// Loader2.jsx

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import '../../css/loader/loader2.css';

const Loader2 = ({ loading }) => {
  return (
    <div
      className={`loader-container ${
        loading ? 'loader-visible' : 'loader-hidden'
      }`}
    >
      <div className='loader-overlay'></div>
      <div className='loader-content'>
        <ClipLoader color={'#1167B1'} loading={loading} size={50} />
      </div>
    </div>
  );
};

export default Loader2;
