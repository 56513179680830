import {
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILED,
  SEARCH_VALUE,
} from '../actions/types';

const initialState = {
  isProductLoaded: false,
};

export default function products(state = initialState, action) {
  // destructure action
  const { type, payload } = action;

  switch (type) {
    case SEARCH_VALUE:
      return {
        ...state,
        ...payload,
        isSearchValue: true,
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        isProductLoaded: true,
      };
    case PRODUCT_LIST_FAILED:
      return {
        ...state,
        ...payload,
        isProductLoaded: false,
      };

    default:
      return state;
  }
}
