import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Components
import { logout } from '../../../actions/auth';
// Images
import expireIcon from '../../../img/expire.png';
// CSS
import '../../../css/modals/subscriptionExpired.css';

const SubscriptionExpired = ({ logout, app_eDate }) => {
  const navigate = useNavigate(); // <-- Initialize useNavigate hook
  const formattedDate = new Date(app_eDate)
    .toLocaleDateString()
    .replace(/\//g, '-');

  const contactButtonAction = async (e) => {
    e.preventDefault();
    localStorage.clear();
    await logout();
    navigate('/contact-us'); // <-- Navigate to the login page after logout
  };

  const cancelButtonAction = async (e) => {
    e.preventDefault();
    localStorage.clear();
    await logout();
    navigate('/login'); // <-- Navigate to the login page after logout
  };
  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        {/* Icon image */}
        <img
          src={expireIcon}
          alt='Subscription Expire Icon'
          className='subs-expire-icon'
        />

        {/* Heading */}
        <h2 className='subs-expire-heading'>Subscription Expire</h2>

        {/* Subtext */}
        <p className='subs-expire-subtext'>
          We regret to inform you that your subscription has expired as of
          <b> {formattedDate}</b>. To continue accessing our services, please
          renew your subscription at your earliest convenience.
        </p>

        {/* Contact us Link */}
        <button
          className='subs-expire-link-button focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 focus:outline-none'
          onClick={contactButtonAction}
        >
          Contact us
        </button>

        {/* Cancel Button */}
        <button
          className='subs-expire-cancel-button focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 focus:outline-none'
          onClick={cancelButtonAction}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

SubscriptionExpired.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(SubscriptionExpired);
