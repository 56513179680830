import React from 'react';

const MasalaAndSpices = ({
  selectedProducts,
  totalProducts,
  handleSelectAll,
  loading,
  getPaginatedItems,
  activeVendor,
  res,
  flipkartItems,
  onCheck,
}) => {
  return (
    <table className='table-auto w-11/12 rounded-lg overflow-hidden bg-white shadow-md'>
      <thead className='border-b border-gray-400'>
        <tr className='text-gray-900 uppercase text-sm leading-normal'>
          <th className='py-3 px-4 text-right'>
            <div className='flex items-center'>
              <input
                type='checkbox'
                checked={selectedProducts.length === totalProducts}
                onChange={handleSelectAll}
                // onChange={selectAll}
                className='rounded-sm'
              />
              <span className='ml-2 font-medium'>All</span>
            </div>
          </th>
          <th className='py-3 px-4 text-center font-medium'>Product Name</th>
          <th className='py-3 px-4 text-center font-medium'>Verified Buyers</th>
          <th className='py-3 px-4 text-center font-medium'>Category</th>
          <th className='py-3 px-4 text-center font-medium'>Comments</th>
          <th className='py-3 px-4 text-center font-medium'>Rating</th>
          <th className='py-3 px-4 text-center font-medium'>Market Place</th>
        </tr>
      </thead>
      {!loading ? (
        <tbody className='text-gray-600 text-sm font-light'>
          {getPaginatedItems(
            activeVendor === 'amazon' ? res : flipkartItems
          ).map((product) => (
            <tr
              key={product.p_id}
              className={`border-b border-gray-200 hover:bg-gray-100 ${
                product.selected ? 'bg-blue-100' : ''
              }`}
            >
              <td className='py-2 px-4 text-left'>
                <input
                  type='checkbox'
                  // checked={selectedProducts.includes(
                  //   `${product['Product URL']}_productName/${product['Product Name']}_price/${product['Price']}_customerRating/${product['Customer Rating']}`
                  // )}
                  checked={selectedProducts.includes(`${product['p_id']}`)}
                  // onChange={() =>
                  //   handleCheckboxChange(product.id)
                  // }
                  onChange={(e) => {
                    onCheck(e);
                  }}
                  className='rounded-sm'
                  value={`${product['p_id']}`}
                />
              </td>
              <td className='py-2 px-4 mx-5 flex  items-center'>
                <div className='relative w-12 h-8 my-2'>
                  <img
                    src={product['Img_url']}
                    alt='Prod img'
                    className='absolute inset-0 object-contain'
                  />
                </div>
                {/* {product.name} */}
                <div className='pl-4'>
                  {product['Product_name'].slice(0, 80) +
                    (product['Product_name'].length > 80 ? '...' : '')}
                </div>
              </td>
              <td className='py-2 px-4 text-center'>{product['No_ratings']}</td>
              <td className='py-2 px-4 text-center'>{product['category']}</td>
              <td className='py-2 px-4 text-center'>{product['No_reviews']}</td>
              <td className='py-2 px-4 text-center'>
                {/* {product.rating} */}
                {product['Product_rating']}
              </td>
              <td className='py-2 px-4 text-center'>
                {product['marketplace']}
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <></>
      )}
    </table>
  );
};

export default MasalaAndSpices;
