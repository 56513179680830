/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import SignInAccess from '../layout/SignInAccess';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import Loader from '../loader/Loader';
import { searchProduct } from '../../actions/products';

// Import Data
import prodData from '../../data/scrappedProducts.json';

// Import Components
import Navbar from '../layout/Navbar';
import ProductCardSearchPage from '../layout/cards/ProductCardSearchPage';
import ProductKeyMetricsModal from '../layout/modals/ProductKeyMetricsModal';
import SubscriptionExpired from '../layout/modals/SubscriptionExpired';

import '../../css/searchPage.css';
import '../../css/analysisType.css';

// Import Images
import logo_sm_color from '../../img/rn_logo_color_1.png';
import category_analysis_icon from '../../img/category-analysis_icon.png';
import category_analysis_faded_icon from '../../img/category_analysis_faded_icon.png';
import product_analysis_icon from '../../img/product_analysis_icon.png';

let AnalysisType = ({
  auth: { isAuthenticated, user },
  setAlert,
  searchProduct,
}) => {
  const [productPage, setProductPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('earphones');
  const [checkedValues, setCheckedValues] = useState([]);
  const [category, setCategory] = useState('electronics');
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [randomObjects, setRandomObjects] = useState([]);
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-gradient');

    return () => {
      document.body.classList.remove('with-gradient');
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the data from the JSON file
        const objects = prodData;

        // Shuffle the array
        const shuffled = objects.sort(() => 0.5 - Math.random());

        // Get sub-array of first 4 elements after shuffling
        const selected = shuffled.slice(0, 4);

        // Set the state of randomObjects
        setRandomObjects(selected);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('App Edn Date', user.app_eDate);
      const blockAccess = user && new Date(user.app_eDate) < new Date();
      console.log('Block access', blockAccess);

      if (blockAccess) {
        setSubscriptionExpired(true);
      } else {
        setSubscriptionExpired(false); // Ensure it's set to false if conditions don't match
      }
    }
  }, [isAuthenticated, user]);

  if (productPage) {
    return <Navigate to='/products-list' />;
  }
  if (isAuthenticated && user) {
    console.log('hello', checkedValues, searchValue, category);
    console.log('cat', category);
    console.log('randomObjects', randomObjects);
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className='relative gradient-background text-center'>
            <Navbar></Navbar>
            <div className='pt-12'>
              <h1 className='search-page-heading'>Make Your Reviews Count</h1>
              <p className='search-page-sub-heading'>
                Reviews tell a story -We help in telling yours
              </p>
              <div className='analysis-option-div flex'>
                <div className='flex items-center'>
                  <Link
                    to='/dashboard'
                    className='product-analysis-link-button flex items-center hover:no-underline'
                  >
                    <img
                      src={product_analysis_icon}
                      alt='product-analysis'
                      className='w-7 h-6 mr-4'
                    />
                    Product Analysis
                  </Link>
                  <Link
                    to=''
                    className='category-analysis-link-button flex items-center  hover:no-underline'
                    disabled
                  >
                    <img
                      src={category_analysis_icon}
                      alt='Category Analysis'
                      className='w-6 h-6 mr-4'
                    />
                    Category Analysis
                  </Link>
                </div>
              </div>
            </div>

            <div className='flex justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2 z-50'>
              {randomObjects.map((obj) => (
                <ProductCardSearchPage
                  price={'$50'}
                  brand={obj.brand}
                  prod_image={obj['image url']}
                  prod_name={obj['product name']}
                  setSelectedProduct={setSelectedProduct}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  bestSellerRank={obj.rank}
                  sentimentScore={obj['sentiment score']}
                  startRating={obj.rating}
                  verifiedReviews={obj['no of verified reviews']}
                  category={obj.category}
                  industry={obj.industry}
                />
              ))}
            </div>

            {showModal && (
              <ProductKeyMetricsModal
                selectedProduct={selectedProduct}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            )}

            {isSubscriptionExpired ? (
              <SubscriptionExpired app_eDate={user.app_eDate} />
            ) : (
              ''
            )}
          </div>
        )}
      </>
    );
  } else if (!isAuthenticated) {
    return <SignInAccess loading={loading}></SignInAccess>;
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  // isRegistered: state.auth.isRegistered,
  // verified: state.auth.user.verified,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, searchProduct })(
  AnalysisType
);
