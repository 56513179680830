import React from 'react';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import SignInAccess from '../layout/SignInAccess';
import Loader from '../loader/Loader';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';

let DocSummarisation = ({ auth: { isAuthenticated, user }, setAlert }) => {
  const [summarizationType, setsummarizationType] = useState('text');
  // const [textLength, setTextLength] = useState(1);
  const [selectedFile, setSelectedFile] = useState('');
  const [fileExt, setFileExt] = useState('');
  const allowedFileTypes = ['xlsx', 'csv', 'pdf', 'txt'];
  const [formdata, setFormData] = useState({
    analysis_type: '',
    file: '',
    file_name: '',
    text: '',
    sentiment: 'Positive',
    sentiment_score: 93.5,
    summary_length: 1,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Destructure formData
  const {
    analysis_type,
    file,
    file_name,
    text,
    sentiment,
    sentiment_score,
    summary_length,
  } = formdata;

  const textAnalysis = async () => {
    // e.preventDefault();
    setsummarizationType('text');
  };
  const docAnalysis = async () => {
    // e.preventDefault();
    setsummarizationType('file');
  };

  const onFileChange = (e) => {
    const fileExtension = e.target.files[0].name.split('.').at(-1);
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ', '
        )}`
      );
      setSelectedFile('');
      setFileExt('');
      return false;
    } else {
      setSelectedFile(e.target.files[0]);
      setFileExt(fileExtension);
      // console.log(fileExtension);
    }
  };

  // console.log(selectedFile);

  const onChange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!allowedFileTypes.includes(fileExt) && summarizationType == 'file') {
      window.alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ', '
        )}`
      );
      return false;
    } else {
      // console.log('summarizationType', summarizationType);
      // console.log('File', selectedFile);
      // console.log('text', text);
      // console.log('file_name', selectedFile.name);

      const formData = new FormData();
      // Update the formData object
      formData.append('myFile', selectedFile, selectedFile.name);

      console.log('form_data', formData);
    }
  };

  // const handleSlideValue = (e) => {
  //   e.preventDefault();
  //   setTextLength(e.target.value);
  // };

  console.log('summarizationType', summarizationType);
  if (isAuthenticated && user) {
    return (
      <>
        <div className='min-h-full'>
          <main>
            <div className='mt-2 flex justify-left'>
              <Link
                to='/converse-ai-products'
                style={{ textDecoration: 'none' }}
              >
                <i class='fa-solid fa-circle-arrow-left'></i> Select Services
              </Link>
            </div>
            <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
              <div className='px-4 py-6 sm:px-0'>
                <div className='w-full'>
                  <span className='text-xl font-medium'>
                    Document Summarization
                  </span>
                </div>
                <div className='w-1/2 flex mt-3 ml-4 justify-left gap-4'>
                  <div className='items-center '>
                    <input
                      id='default-radio-1'
                      type='radio'
                      value=''
                      name='default-radio'
                      // onChange={(e) => textAnalysis(e)}
                      onClick={textAnalysis}
                      defaultChecked={summarizationType === 'text'}
                      className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      for='default-radio-1'
                      className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      Text Summarization
                    </label>
                  </div>
                  <div className='items-center'>
                    <input
                      id='default-radio-2'
                      type='radio'
                      value=''
                      name='default-radio'
                      // onChange={(e) => docAnalysis(e)}
                      onClick={docAnalysis}
                      defaultChecked={summarizationType === 'file'}
                      className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label
                      for='default-radio-2'
                      className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      File Summarization
                    </label>
                  </div>
                </div>
                <div className='w-full flex justify-center'>
                  <div className='w-1/2'>
                    <div className='w-full'>
                      <textarea
                        className='mt-4 py-1 px-1 ml-4 max-w-full disabled:cursor-not-allowed hover:disabled:cursor-not-allowed'
                        rows='10'
                        cols='60'
                        name='analysis-text'
                        placeholder='Place your text here...'
                        disabled={summarizationType === 'file'}
                        onChange={(e) => onChange(e)}
                      ></textarea>
                    </div>
                    <div className='flex mt-2 ml-4'>
                      <label
                        for='small-range'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        Summary Length
                      </label>
                    </div>
                    <div className='w-full ml-4 flex justify-left items-center'>
                      <div className='w-3/4'>
                        <input
                          id='summary_length'
                          type='range'
                          value={summary_length}
                          className=' w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm dark:bg-gray-700 disabled:cursor-not-allowed hover:disabled:cursor-not-allowed'
                          min='1'
                          max='100'
                          step='1'
                          name='summary_length'
                          onChange={(e) => onChange(e)}
                          disabled={summarizationType === 'file'}
                        />
                      </div>
                      <div className='w-1/4 flex justify-left pl-4'>
                        <span>{summary_length}</span>
                      </div>
                    </div>
                    <div className='w-full flex mt-3'>
                      {/* <label
                          className='mt-1 py-1 px-1 ml-4 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                          for='file_input'
                        >
                          Upload file
                        </label> */}

                      <input
                        className='mt-1  ml-4 mr-4 block w-full text-sm text-grey-500
                        file:mr-5 file:py-2 file:px-6
                        file:rounded-lg file:border-0 border
                        file:text-sm file:font-medium
                        file:bg-blue-50 file:text-blue-700
                        hover:file:cursor-pointer 
                         disabled:cursor-not-allowed hover:file:disabled:cursor-not-allowed'
                        aria-describedby='file_input_help'
                        id='file_input'
                        type='file'
                        disabled={summarizationType === 'text'}
                        onChange={(e) => onFileChange(e)}
                      />
                    </div>
                    <div className='w-full flex mt-0 ml-4 text-xs text-gray-500 dark:text-gray-300'>
                      XLSX, CSV, PDF OR TXT
                    </div>
                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={(e) => onSubmit(e)}
                        className='mt-3 ml-4 text-white bg-blue-10 hover:bg-blue-20 focus:ring-2 focus:ring-blue-10 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                      >
                        Summarize
                      </button>
                    </div>
                  </div>
                  <div className='w-1/2'>
                    <fieldset className='border p-2 h-96'>
                      <legend className='float-none w-auto p-2'>Output</legend>
                      <div className='relative h-full'>
                        <div className='w-3/4'>
                          Result adhhhhhhhhhhhhhhhhhhfaldsfj aldsfajskdf
                          asdjflasjdf ajdfa ndsfjlasfd asndflafa
                        </div>
                        <div className='absolute right-0 top-0 w-16'>
                          <i className='fa-solid fa-copy'></i>
                          <span className='ml-1'>Copy</span>
                        </div>

                        <div className='absolute bottom-0 right-0 w-21'>
                          <i className='fa-solid fa-download'></i>
                          <span className='ml-1'>Download</span>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );

    // return (
    //   <>

    //   </>
    // );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  // isRegistered: state.auth.isRegistered,
  // verified: state.auth.user.verified,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(DocSummarisation);
