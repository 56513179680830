import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  OTP_VERIFIED,
  OTP_FAILED,
  PASSWORD_RESET_LINK_SUCCESS,
  PASSWORD_RESET_LINK_FAILED,
  PASSWORD_RESET,
  VERIFY_RESET_LINK_SUCCESS,
  VERIFY_RESET_LINK_FAILED,
  VISIT_FORGOT_PASSWORD_LINK,
  USER_OTP_VERIFICATION,
  // LOGOUT_FAIL,
} from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { toast } from 'react-toastify';

// LOAD USER
export const loadUser = () => async (dispatch) => {
  // check loacl storage fo ttoken
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    console.log('process', process.env.REACT_APP_API_URI);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/auth`
    );

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const afterRegister = () => async (dispatch) => {
  // check loacl storage for token
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({
      type: USER_OTP_VERIFICATION,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register user
export const register =
  ({ name, email, password, mobile, company }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const body = JSON.stringify({
      user_name: name,
      user_email: email,
      user_password: password,
      user_mobile_number: mobile,
      user_company: company,
      app_name: 'review_now',
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/review-now/api/register`,
        body,
        config
      );

      if (res.data.msg === 'User already Exists') {
        console.log('register', res.data.msg, res.status);

        return toast.error(res.data.msg);
      } else {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });

        dispatch(afterRegister());
      }
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
          dispatch(toast.error(error.msg));
        });
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Update User Profile
export const updateProfile =
  ({ name, email, password, mobile_number, company_name }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const body = JSON.stringify({
        user_name: name,
        user_password: password,
        user_mobile_number: mobile_number,
        user_company: company_name,
        user_email: email,
      });

      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/review-now/api/update-user`,
        body,
        config
      );

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
          dispatch(toast.error(error.msg));
        });
      }
    }
  };

// Login user
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    user_email: email,
    user_password: password,
  });

  try {
    console.log('process', process.env.REACT_APP_API_URI);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URI}/review-now/api/login`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(toast.error(error.msg));
      });
    }

    dispatch({
      type: LOGIN_FAILED,
    });
  }
};

// LOGOUT / Clear Profile and Token from local storage
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

// OTP verification
export const otpVerification = (otp) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    otp,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URI}/review-now/api/verify-email`,
      body,
      config
    );

    dispatch({
      type: OTP_VERIFIED,
      payload: res.data,
    });

    // dispatch(loadUser());
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
        dispatch(toast.error(error.msg));
      });
    }

    dispatch({
      type: OTP_FAILED,
    });
  }
};

// Password Reset Link
export const passwordResetLink = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    email,
  });

  try {
    console.log('test', email);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URI}/review-now/api/forgot-password`,
      body,
      config
    );

    console.log('res', res);
    dispatch({
      type: PASSWORD_RESET_LINK_SUCCESS,
      payload: res.data,
    });

    // dispatch(loadUser());
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
        dispatch(toast.error(error.msg));
      });
    }

    dispatch({
      type: PASSWORD_RESET_LINK_FAILED,
    });
  }
};

// Password Reset Link
export const resetPassword = (password, token, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    password,
  });

  try {
    console.log('test', password, token, id);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URI}/review-now/api/reset-password?token=${token}&id=${id}`,
      body,
      config
    );

    console.log('res', res);
    dispatch({
      type: PASSWORD_RESET,
      payload: res.data,
    });
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
        dispatch(toast.error(error.msg));
      });
    }

    dispatch({
      type: PASSWORD_RESET_LINK_FAILED,
    });
  }
};

// Verify Reset Link
export const verifyResetLink = (token, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  const body = JSON.stringify({
    token,
    id,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/verify-reset-token?token=${token}&id=${id}`,
      body,
      config
    );

    console.log(res);

    dispatch({
      type: VERIFY_RESET_LINK_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
        dispatch(toast.error(error.msg));
      });
    }

    dispatch({
      type: VERIFY_RESET_LINK_FAILED,
    });
  }
};

// Login user
export const loadDashboarduser = () => async (dispatch) => {
  try {
    dispatch(loadUser());
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
        dispatch(toast.error(error.msg));
      });
    }
  }
};

// LOAD USER
export const getUser = () => async (dispatch) => {
  // check loacl storage fo ttoken
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/auth`
    );

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Click Forgot Password Link
export const clickForgotPassword = () => async (dispatch) => {
  console.log('auth action 1');
  dispatch({
    type: VISIT_FORGOT_PASSWORD_LINK,
  });
  console.log('auth action 2');
};
