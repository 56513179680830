import { Link, Navigate, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, clickForgotPassword } from '../../actions/auth';
import { toast } from 'react-toastify';
// import '../../css/login.css';
import '../../css/auth/login.css';
import Loader from '../loader/Loader';
import '../../css/auth/register.css';

// Image Imports
// import logo_sm_color from '../../img/logo_sm_color.png';
import review_now_logo_color from '../../img/review_now_logo_color.png';
import background from '../../img/bg_3.png';

let Login = ({ login, isAuthenticated, clickForgotPassword }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  // Destructure formData
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    login(email, password)
      .then((e) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // console.log(formData);
    // console.log('Success');
  };

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();

    console.log('click on event');

    // Call action here
    await clickForgotPassword();
    console.log('below action function');

    //navigate to link page
    navigate('/email-forget-password');
    console.log('below navigate');
  };

  // Redirect if logged in
  if (isAuthenticated) {
    // toast.success('Logged In Success');
    return <Navigate to='/analysis-type' />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={` min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow'>
            {/* Left Area */}
            <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
              <div className='data w-4/5 flex flex-col items-center justify-center bg-white rounded-lg p-8'>
                <h2 className='form-heading'>Login</h2>
                <form
                  className='w-full'
                  onSubmit={(e) => onSubmit(e)}
                  method='POST'
                >
                  <input type='hidden' name='remember' defaultValue='true' />

                  <div className='mb-4'>
                    <input
                      id='email-address'
                      name='email'
                      type='email'
                      value={email}
                      onChange={(e) => onChange(e)}
                      autoComplete='email'
                      required
                      placeholder='Email'
                      className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      value={password}
                      onChange={(e) => onChange(e)}
                      autoComplete='current-password'
                      required
                      placeholder='Password'
                      className='password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>

                  <div className='text-sm flex justify-end mt-2'>
                    <Link
                      to='/email-forget-password'
                      className='forgot-password hover:text-hover_2-10'
                      onClick={(e) => handleClickForgotPassword(e)}
                    >
                      Forgot your password?
                    </Link>
                  </div>

                  <button
                    type='submit'
                    className='register-button text-white py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider focus:outline-none'
                  >
                    Login
                  </button>
                </form>
                <p className='text-center'>
                  Don't have an account?{' '}
                  <Link to='/register' className='login-link no-underline'>
                    Register
                  </Link>
                </p>
              </div>
            </div>

            {/* Right Area */}
            <div className='flex-grow ml-8 flex items-center justify-left'>
              {' '}
              {/* Added flex classes here */}
              <div className='rounded-lg p-8'>
                <h2 className='side-text-1'>Generating</h2>
                <h2 className='side-text-2'>Consumer Insights:</h2>
                <h2 className='side-text-3'>Through</h2>
                <h2 className='side-text-4'>Generative AI</h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

login.PropTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, clickForgotPassword })(Login);
