import { LockClosedIcon } from '@heroicons/react/solid';
import inferenceLogo from '../../img/inference.png';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { resetPassword, verifyResetLink } from '../../actions/auth';
import queryString from 'query-string';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

import '../../css/resetPassword.css';

// Import Images

import invalid_link_icon from '../../img/invalid_link_icon.png';
import invalid_link_bg from '../../img/invalid_link_bg.png';
import review_now_logo_color from '../../img/review_now_logo_color.png';
import createNewPassVector from '../../img/createNewPassVector.png';
import invalidLinkIcon from '../../img/invalidLinkIcon.png';

let ResetPassword = ({
  isResetLinkValid,
  resetPassword,
  verifyResetLink,
  setAlert,
  isPasswordReset,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  // Destructure formData
  const { password, password2 } = formData;

  const location = useLocation();
  console.log(location);

  const { token, id } = queryString.parse(location.search);

  const verifyLink = async () => {
    await verifyResetLink(token, id);
  };

  useEffect(() => {
    verifyLink();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      // setAlert('Password does not match', 'danger');
      toast.error('Password does not match!');
      console.log('Password does not matches');
    } else {
      setIsLoading(true);
      await resetPassword(password, token, id)
        .then((e) => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      console.log('Success');
      // return <Navigate to='/login' replace={true} />;
    }
  };

  console.log('isPasswordReset', isPasswordReset);
  // Redirect if Password reset success
  if (isPasswordReset) {
    toast.success('Password reset successfully');
    return <Navigate to='/login' />;
  }

  if (!isResetLinkValid)
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={`min-h-screen p-4 flex flex-col`}>
            {/* Top Section */}
            <div className='flex justify-between items-center mb-8'>
              <div className='flex items-center'>
                <img
                  src={review_now_logo_color}
                  alt='Logo'
                  className='navbar-logo'
                />
                <Link
                  to='/login'
                  className='ml-2 navbar-logo-text hover:no-underline'
                >
                  Review Now
                </Link>
              </div>
              <div className='space-x-4'>
                <Link
                  to='/about-us'
                  className='about-us-navbar hover:no-underline'
                >
                  About us
                </Link>
                <Link
                  to='/contact-us'
                  className='contact-us-navbar hover:no-underline'
                >
                  Contact
                </Link>
              </div>
            </div>

            {/* Main Content */}
            <div className='flex flex-grow justify-center items-center'>
              <div className='text-center bg-white p-8 rounded-lg invalid-link-div shadow-xl'>
                <img
                  src={invalidLinkIcon}
                  alt='Logo'
                  className='invalid-link-icon mx-auto'
                />
                <h1 className='invalid-link-heading'>Invalid Link</h1>
                <p className='invalid-link-sec-text'>
                  We regret to inform you that the reset password link you
                  attempted to use is invalid. Please make a new request to
                  reset your password.
                </p>
                <Link
                  to='/email-forget-password'
                  className='invalid-back-button inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-hover_1-10 focus:ring-offset-2'
                >
                  Back to Forgot Password
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={`min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow'>
            {/* Left Area */}
            <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
              <div className='w-4/5 flex flex-col items-center justify-center bg-white rounded-lg p-12'>
                <h2 className='reset-password-h3'>Create New Password</h2>
                <p className='reset-password-secondary-text text-center'>
                  Create a new password. Password must be at least 6 characters
                  long{' '}
                </p>
                <form
                  className='w-full'
                  onSubmit={(e) => onSubmit(e)}
                  method='POST'
                >
                  <input type='hidden' name='remember' defaultValue='true' />

                  <div className='mb-4'>
                    <input type='hidden' name='remember' defaultValue='true' />
                    <input
                      id='password'
                      name='password'
                      type='password'
                      value={password}
                      onChange={(e) => onChange(e)}
                      required
                      placeholder='Enter new password'
                      title='Please enter a 6 characters length password'
                      className='create-password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='password2'
                      name='password2'
                      type='password'
                      value={password2}
                      onChange={(e) => onChange(e)}
                      required
                      placeholder='Confirm new password'
                      className='create-password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                      title='Please enter a 6 characters length password'
                    />
                  </div>

                  <button
                    type='submit'
                    className='register-button text-white py-2 px-4 rounded-lg w-full mb-4 mt-2 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider focus:outline-none'
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>

            {/* Right Area */}
            <div className='flex-grow ml-8 flex items-center justify-left'>
              {' '}
              {/* Added flex classes here */}
              <div className='rounded-lg p-8'>
                <img
                  src={createNewPassVector}
                  alt='createNewPassVector'
                  className='create-new-pass-vector'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // isPasswordReset: PropTypes.bool.isRequired,
  // isResetLinkValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPasswordReset: state.auth.isPasswordReset,
  isResetLinkValid: state.auth.isResetLinkValid,
  isTokenValid: state.auth.isTokenValid,
});

export default connect(mapStateToProps, {
  setAlert,
  resetPassword,
  verifyResetLink,
})(ResetPassword);
