import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const Embeddedreports = () => {
  return (
    <div>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: '89add528-fbdc-4e69-9bdf-44cd2e4d4fd2',
          embedUrl:
            'https://app.powerbi.com/reportEmbed?reportId=89add528-fbdc-4e69-9bdf-44cd2e4d4fd2&groupId=3be740d8-6854-438e-bbb3-45c9421a0e89&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
          accessToken:
            'H4sIAAAAAAAEAB3Tx66DRgBA0X95WyJRjSFSFqb33nfUwdTBNEOUf89L9nd1pPv3j5Nfw5xXP3_-VJUpR2U6c6hrXIgJA0h34ij0BE5xyoQr7h6NfEz4hBJiq4LXac3696m-hVkl4rw_qSU4SY7obt4sfORBSHkNF-QdNxOVnJnvOA8dwhHdg4ICJzmvN8H1nXhfzoNBI2nn3o8SA45ns0gJI7qYz_ukg3kZJt8rWYBSS_bOv8Mwdw9xq4gjvJEAUVFFaPjv49FE853pFOmfnF081QHF_Opaz3Py-CV8fZQTmRnGduLeTnkTOTIonpRIucORBokSi2wVXp-t1McMwsf7ktBmqLqHmY30kj4rzRiGjFXFxRfitYX7Wjd6m7szc1-NNu9iOaJbckCHpbBM9HfbPiobV9UJ2xsBJDbRnZ9pQDeFTO0s4CgUua1Q6apHv6VOWzkpiIb529FWhieVU6pc0cyvLVKsZi9RhviIU-LD0S6j4Lx3pUG-iVWGNuxvjHfv5cmKEUC8aOM712hHevRkR0TSFmQaUpYgYSq-falxFzLmAEkMFksWm61btxA5TwNQgsqdRkPf7lDqZIetXv2qQ79PCpQXDFN71d7a-_jCC8BV2qrzyfMR1BC6L9AobWi2XIftxrr13p7xNEv6m8XyaRJn62_e7ps7EQjNb7YBoyfW280MZCBksbgyrlbgJJoCGpHWrzO6tshMVZ94kabqMCb6SZtGXjKmmA3MxycePo-xspkHd8lLrCP4GsomIhwBy57xzK6Ko3CuPNNie3KQelsZ00MZh9qbeGuw59hsuyW_ZxoFQ9y8d8iSIIfmSsTZxeRCZ5uJkbavrLAIuihcZ-PebYGMjYEjgjAYqcEEmdWQngbs6xx1lIlyLfgOuzgTtGTk36aYf_744T8X3Ga9vn53wiosDNxws1_m4egp_akooK_lRiTicagdJ8a0R6oxQfbkS0Kfqj_Asff3D5d4DFDYNoH3gMUJh3C6bk1pWyKJzbh0pGhiJjQAnwwHxyZtnIYRI0Y7VsySVyKqzEjAh9sRFXL9ntoc0mVfsVHOXXqJQymq5vZATuRylfKGA9urYHKMXcSGsGFCtSZ1aePw7NnR_oiM3CTJBXRdoFamU3BJr_TSIbwnt3kcUr2G13bDJ1YSLF2m69qz35msgoAEyvFACitFnnhj7jwSuejevZjSGqFmmufHl0NTZsZ5EWKm0PtmFJyWqagk8qIcRKGxy_U9uez7MnnR16GW3GpfLxKVxq-__vqP-YJt_VGjX2VC_IL-QitCM-bQuFa5LS_d_b_y32DKt_1T_2ZxZ5E0vu4vlWNe8VDhGuAcT0QXS6cqd2mf6REL1TCEBdck8vvOYfRJhXI0R2o3uH5GTdrf22fIW-pAa_u0KAdnkQ69EwLK6y276sU3utX80tTIqFkOR4azsGzGUbe08KG9RAJ_KUuagdQwCLF4fVknomOInm293C3rJlP7PM2qZ6WpxEQ0iTiyVNu8kG_cc1d0V7FXVTO2eMLbY_L7SWpxMM_mQNhETiP52nqaW_Pxm1mwQQi91x0TEj3zSmz77_GZhigRPpXGpHd0Ut0HwyzfQJTMBMmfNP89grEXYDc3AaQ6msvsuGwtRZDBcnfzNVldFKLZ16EsoE2dTzN64GPoSp2_zP_8C7u8GNhCBgAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNjkzMjkzMjI3LCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=',
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                console.log('Report loaded');
              },
            ],
            [
              'rendered',
              function () {
                console.log('Report rendered');
              },
            ],
            [
              'error',
              function (event) {
                console.log(event.detail);
              },
            ],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }
        cssClassName={'reportClass'}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </div>
  );
};

export default Embeddedreports;
