import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import defaultProfilePic from '../../../img/defaultProfilePic.png';

// Import components
import ServiceList from '../otherComponents/ServiceList';

// CSS
import '../../../css/profile/manageUser.css';

const ManageUserModal = ({
  show,
  onManage,
  userDetails,
  onCheck,
  checkedValues,
  onClose,
  onSave,
  onChange,
  selectedDate,
  dateObj,
}) => {
  // console.log('UD', userDetails);

  // const [superUser, setSuperUser] = useState(false);

  const [startDate, setStartDate] = useState(
    userDetails.userDetails.app_sDate
      ? new Date(userDetails.userDetails.app_sDate)
      : null
  );
  const [endDate, setEndDate] = useState(
    userDetails.userDetails.app_eDate
      ? new Date(userDetails.userDetails.app_eDate)
      : null
  );

  // const [checkedValues, setCheckedValues] = useState([]);

  // Calculate today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-6xl'>
          {/*content*/}
          <div className=' manage-user-modal-width border-0 rounded-lg shadow-lg relative bg-white outline-none focus:outline-none'>
            {/*header*/}
            {/*body*/}
            <div className='relative p-6 flex-auto'>
              <div className='flex flex-wrap'>
                <div className='mx-1 flex-auto w-auto'>
                  <div className='manage-user-modal-name flex justify-center'>
                    <p>{userDetails.userDetails.user_name}</p>
                  </div>

                  <div className='flex justify-between mb-3'>
                    <p className='manage-user-account-created'>
                      {' '}
                      Account Created on:{' '}
                    </p>
                    <p className='manage-user-account-created'>
                      {userDetails.userDetails.user_createdAt}
                    </p>
                  </div>

                  <div className='flex justify-between mb-8'>
                    <p className='text-xs'> Super User </p>
                    <p className='text-xs'>
                      <label
                        for='small-toggle'
                        class='inline-flex relative items-center mb-3 cursor-pointer'
                      >
                        <input
                          type='checkbox'
                          value='user_isSuperUser'
                          id='small-toggle'
                          class='sr-only peer'
                          checked={checkedValues.includes('user_isSuperUser')}
                          onClick={(e) => onCheck(e)}
                        />
                        <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mr-2"></div>
                      </label>
                    </p>
                  </div>

                  <div className='flex justify-left mb-4'>
                    <p className='manage-user-subscription'> Subscription</p>
                  </div>

                  <div className='flex justify-between mb-3 items-center'>
                    <p className='manage-user-start-date'> Start Date</p>
                    <DatePicker
                      className='manage-user-date-box-style'
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText='Start Date'
                      dateFormat='MM/dd/yyyy' // Adjust the date format as needed
                      minDate={today} // Minimum date is today
                    />
                  </div>
                  <div className='flex justify-between mb-9 items-center'>
                    <p className='manage-user-end-date'> End Date </p>
                    <DatePicker
                      className='manage-user-date-box-style'
                      selected={endDate}
                      onChange={(date) => {
                        if (date < startDate) {
                          // Ensure end date is not less than start date
                          date = startDate;
                        }
                        setEndDate(date);
                      }}
                      placeholderText='End Date'
                      dateFormat='MM/dd/yyyy' // Adjust the date format as needed
                      minDate={
                        startDate
                          ? new Date(startDate.getTime() + 86400000)
                          : today
                      } // Minimum date is one day after start date
                      // maxDate={today} // Maximum date is today (if you want to disallow today's date)
                    />
                  </div>

                  <div className='flex justify-center gap-4'>
                    <button
                      type='button'
                      className='manage-user-update-button py-1.5 px-3 text-center focus:ring-2 focus:outline-none focus:ring-hover_1-10 dark:bg-green-600 focus:ring-offset-2 dark:hover:bg-green-700 dark:focus:ring-zinc-green'
                      onClick={(e) => {
                        onSave(e, startDate, endDate);
                      }}
                      value={userDetails.userDetails.user_id}
                    >
                      Update
                    </button>
                    <button
                      type='button'
                      className='manage-user-cancel-button py-1.5 px-3 text-center focus:ring-2 focus:ring-offset-2 focus:outline-none focus:ring-zinc-300 dark:bg-zinc-600 dark:hover:bg-zinc-700 dark:focus:ring-zinc-800'
                      onClick={() => {
                        onClose(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
};

export default ManageUserModal;
