/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react';

import { Link, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import compeet from '../../img/compejvbsd.png';
import CSAT from '../../img/CSAT.png';
import NPS from '../../img/NPS.png';
import ProductReview from '../../img/ProductReview.png';

import SignInAccess from '../layout/SignInAccess';
import Loader from '../loader/Loader';

let Templates = ({ auth: { isAuthenticated, user }, setAlert }) => {
  const [selected, setSelected] = useState(false);
  const [buttonText, setButtonText] = useState('Select All Reports');
  const [pbi_reports, setPbi_reports] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const selectAll = () => {
    setSelected(!selected);
    if (!selected) {
      setButtonText('Deselect All Reports');
    } else {
      setButtonText('Select All Reports');
    }
  };

  const reports = () => {
    if (selected) {
      setPbi_reports(true);
    } else {
      setAlert('Password does not match', 'danger');
    }
  };

  if (pbi_reports) {
    return <Navigate to='/reports-power-bi' />;
  }
  if (isAuthenticated && user) {
    return (
      <>
        <div className='min-h-full'>
          <main>
            <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
              <div className='flex justify-between'>
                <div>
                  <Link to='/products-list' style={{ textDecoration: 'none' }}>
                    <i class='fa-solid fa-circle-arrow-left'></i> Select your
                    Products
                  </Link>
                </div>
                <div>
                  <div className='form-check form-check-inline'>
                    <input
                      id='flowbite'
                      aria-describedby='flowbite'
                      type='checkbox'
                      className='bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded'
                      checked
                    />
                    <label
                      for='flowbite'
                      className='form-check-label inline-block text-sm ml-2 font-medium text-gray-900'
                    >
                      190 Products Selected
                    </label>
                    <button
                      type='button'
                      onClick={selectAll}
                      class='text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2 mb-2 ml-4'
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <fieldset className='border p-2 h-96'>
                    <legend className='float-none w-auto p-2'>
                      Product Review Analysis
                    </legend>
                    <div className='relative pb-48 overflow-hidden h-full'>
                      <img
                        className='absolute inset-0 h-full w-full object-cover'
                        src={ProductReview}
                        // src={object['Image Link']}
                        alt=''
                      />
                      <input
                        type='checkbox'
                        checked={selected}
                        className='absolute '
                      />
                      {/* </label> */}
                    </div>
                  </fieldset>
                </div>
                <div>
                  <fieldset className='border p-2 h-96'>
                    <legend className='float-none w-auto p-2'>
                      Competitor Analysis
                    </legend>
                    <div className='relative pb-48 overflow-hidden h-full'>
                      <img
                        className='absolute inset-0 h-full w-full object-cover'
                        src={compeet}
                        // src={object['Image Link']}
                        alt=''
                      />
                      <input
                        type='checkbox'
                        checked={selected}
                        className='absolute '
                      />
                      {/* </label> */}
                    </div>
                  </fieldset>
                </div>
                <div>
                  <fieldset className='border p-2 h-96'>
                    <legend className='float-none w-auto p-2'>
                      CSAT Intelligence
                    </legend>
                    <div className='relative pb-48 overflow-hidden h-full'>
                      <img
                        className='absolute inset-0 h-full w-full object-cover'
                        src={CSAT}
                        // src={object['Image Link']}
                        alt=''
                      />
                      <input
                        type='checkbox'
                        checked={selected}
                        className='absolute '
                      />
                      {/* </label> */}
                    </div>
                  </fieldset>
                </div>
                <div>
                  <fieldset className='border p-2 h-96'>
                    <legend className='float-none w-auto p-2'>
                      NPS Intelligence
                    </legend>
                    <div className='relative pb-48 overflow-hidden h-full'>
                      <img
                        className='absolute inset-0 h-full w-full object-cover'
                        src={NPS}
                        // src={object['Image Link']}
                        alt=''
                      />
                      <input
                        type='checkbox'
                        checked={selected}
                        className='absolute '
                      />
                      {/* </label> */}
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='flex justify-center'>
                {/* <button
                type='button'
                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
              >
                Next
              </button> */}

                <button
                  onClick={reports}
                  type='button'
                  disabled={!selected}
                  className='btn-sm text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-md px-5 py-2.5 text-center mt-4 disabled:opacity-60'
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </>
    );

    // return (
    //   <>

    //   </>
    // );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(Templates);
