/* This example requires Tailwind CSS v2.0+ */
import { useEffect, Fragment, useState } from 'react';

import { Link, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LockClosedIcon } from '@heroicons/react/solid';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import Loader from '../loader/Loader';
import SignInAccess from '../layout/SignInAccess';
import Rating from '../layout/Rating';
import {
  cosmetics,
  Masala_and_Spices,
  audio,
  getProductList2,
} from '../../actions/products';
import ProductCard from '../layout/cards/ProductCard';
import { selectedProductUrls } from '../../actions/products';
import Navbar from '../layout/Navbar';
// import ProductCard from '../layout/cards/ProductCard';

// CSS
import '../../css/productsList.css';

// Images
import products_icon from '../../img/products_icon.png';
import logo_sm_color from '../../img/ic_logo_color.png';
import CSAT from '../../img/CSAT.png';
import MasalaAndSpices from '../prodTables/MasalaAndSpices';
import Headphones from '../prodTables/Headphones';
import Cosmetics from '../prodTables/Cosmetics';
import { toast } from 'react-toastify';

// Data
const data = require('../../data/amazon.json');
const prodList = require('../../data/productListReviewNow.json');

// const shops = ['amazon', 'flipkart'];

let StaticProdList = ({
  auth: { isAuthenticated, user },
  products: { isProductLoaded },
  setAlert,
  getProductList2,
  selectedProductUrls,
  Masala_and_Spices,
  cosmetics,
  audio,
}) => {
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState();
  const [amazonItems, setAmazonItems] = useState();
  const [flipkartItems, setFlipkartItems] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [activeVendor, setActiveVendor] = useState('amazon');
  const [staticProdList, setStaticProdList] = useState();
  // const [vendor_name, setVendorName] = useState(['amazon']);
  const [vendor_name, setVendorName] = useState(
    localStorage.getItem('vendor') || 'default_vendor'
  );
  const [search_value, setSearchValue] = useState(
    localStorage.getItem('search_value') || 'default_search_value'
  );

  const [products, setProducts] = useState([
    {
      id: 1,
      name: 'Product 1',
      verifiedBuyers: 50,
      category: 'Category 1',
      comments: 10,
      rating: 4.5,
      selected: false,
    },
    {
      id: 2,
      name: 'Product 2',
      verifiedBuyers: 100,
      category: 'Category 2',
      comments: 20,
      rating: 4.2,
      selected: false,
    },
    // add more products as needed
  ]);
  const [currentPage, setCurrentPage] = useState(1);

  // useEffect(() => {
  //   document.body.classList.add('with-gradient');

  //   return () => {
  //     document.body.classList.remove('with-gradient');
  //   };
  // }, []);

  useEffect(() => {
    // Fetch the data from localStorage and set it in the state
    const storedVendor = localStorage.getItem('vendor');
    if (storedVendor) {
      setVendorName(JSON.parse(storedVendor));
    }
  }, []);

  useEffect(() => {
    getProductList2(search_value, vendor_name).then((res) => {
      setRes(res.data.records);
      console.log('API data', res.data.records.length);
      // setAmazonItems(res.data.amazon);
      // setFlipkartItems(res.data.flipkart);
      setLoading(false);
    });

    // setStaticProdList(prodList);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000);
  }, []);

  const getPaginatedItems = (items) => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return items.slice(startIndex, endIndex);
  };

  const totalAmazonItems = res ? res.length : 0;
  const totalProducts = totalAmazonItems;

  const totalPages = Math.ceil(totalAmazonItems / 10);

  // Checkbox Change Handler
  const onCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      console.log(e.target.value);
      setSelectedProducts([...selectedProducts, value]);
    } else {
      setSelectedProducts(selectedProducts.filter((e) => e !== value));
      // setSelectAll(!selectAll);
    }
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const removeSelected = () => {
    console.log('Remove Selected');
    setSelectedProducts([]);
  };

  const handleCheckboxChange = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, selected: !product.selected }
          : product
      )
    );
  };

  const handleSelectAll = () => {
    let tempArr = [];
    res.map((obj) => {
      tempArr.push(`${obj['p_id']}`);
    });
    setSelectedProducts([...tempArr]);
    // console.log('temp Arr', tempArr);

    if (selectAll) {
      setSelectedProducts([]);
    }
    setSelectAll(!selectAll);
  };

  if (isProductLoaded) {
    console.log('res', res);
    // console.log('amazon Items', amazonItems);
    // console.log('flipkart Items', flipkartItems);
  }

  const selectTemplates = () => {
    // console.log(selectedProducts);
    setLoading(true);

    selectedProductUrls(selectedProducts, search_value, vendor_name)
      .then((res) => {
        console.log('save data res', res);

        // Assuming search_value is available in your scope
        switch (search_value) {
          case 'Masala_and_Spices':
            return Masala_and_Spices();

          case 'cosmetics':
            return cosmetics();

          case 'audio':
            return audio();
          // Add more cases for other values if needed

          default:
            // Handle the default case or return a default function
            return toast.warn('Report Issue');
        }
      })

      .then((secondApiRes) => {
        console.log('second API response', secondApiRes);
        setLoading(false);
        setNextPage(true);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
        setLoading(false);
      });
  };

  console.log('nextPage-1', nextPage);
  if (nextPage) {
    return <Navigate to='/reports-power-bi' />;
  }

  console.log('isProductLoaded', isProductLoaded);
  console.log('selected products', selectedProducts);
  if (isAuthenticated && user) {
    return (
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div className='background-prod-list min-h-full'>
            <Navbar></Navbar>
            <div className='pb-4 mt-5 pl-5'>
              <Link
                to='/dashboard'
                className='ml-2 hover:no-underline back-link-button hover:text-primary_1-10'
              >
                <i class='fa-solid fa-chevron-left mr-1'></i> Search Product
              </Link>
            </div>
            <div className='flex items-center justify-between px-4'>
              <div className='flex items-center ml-4'>
                <h1 className='product-list-heading'>Product List</h1>
                {/* {vendor_name.includes('amazon') ||
                vendor_name.includes('flipkart') ? (
                  <div className='flex items-center space-x-4 ml-4'>
                    {vendor_name.includes('amazon') && (
                      <button
                        className={`vendor-button ${
                          activeVendor === 'amazon'
                            ? 'button-bg-gradient text-white focus:outline-none'
                            : 'focus:outline-none'
                        }`}
                        onClick={() => setActiveVendor('amazon')}
                      >
                        Amazon
                      </button>
                    )}
                    {vendor_name.includes('flipkart') && (
                      <button
                        className={`vendor-button ${
                          activeVendor === 'flipkart'
                            ? 'button-bg-gradient text-white focus:outline-none'
                            : 'focus:outline-none'
                        }`}
                        onClick={() => setActiveVendor('flipkart')}
                      >
                        Flipkart
                      </button>
                    )}
                  </div>
                ) : null} */}
              </div>
              <div className='flex items-center space-x-4 mr-4 pr-2'>
                <p className='selected-products flex items-center'>
                  <img
                    src={products_icon}
                    alt='Products Icon'
                    className='w-3 h-3 mr-2'
                  />
                  Selected Product:{' '}
                  <span className='selected-products-value'>
                    {selectedProducts.length}
                    {/* {selectedProducts.length ? '+' : ' '} */}
                  </span>
                </p>
                <p className='total-products'>
                  Total Products:{' '}
                  <span className='total-products-value'>{totalProducts}</span>
                </p>
                {/* <p className='total-products'>
                  Total Products:{' '}
                  <span className='total-products-value'>{totalProducts}</span>
                </p> */}
                <button
                  className='generate-report-button focus:outline-none hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                  onClick={selectTemplates}
                  disabled={selectedProducts.length === 0}
                  title={
                    selectedProducts.length === 0 ? 'Select All Products' : ''
                  }
                >
                  <i class='fa-solid fa-file-signature'></i> Generate Analytics
                </button>
              </div>
            </div>
            <div className='flex items-center justify-center mt-3 pb-4'>
              {(() => {
                switch (search_value) {
                  case 'audio':
                    return (
                      <Headphones
                        selectedProducts={selectedProducts}
                        totalProducts={totalProducts}
                        handleSelectAll={handleSelectAll}
                        loading={loading}
                        getPaginatedItems={getPaginatedItems}
                        activeVendor={activeVendor}
                        res={res}
                        flipkartItems={flipkartItems}
                        onCheck={onCheck}
                      />
                    );
                  case 'Masala_and_Spices':
                    return (
                      <MasalaAndSpices
                        selectedProducts={selectedProducts}
                        totalProducts={totalProducts}
                        handleSelectAll={handleSelectAll}
                        loading={loading}
                        getPaginatedItems={getPaginatedItems}
                        activeVendor={activeVendor}
                        res={res}
                        flipkartItems={flipkartItems}
                        onCheck={onCheck}
                      />
                    );
                  case 'cosmetics':
                    return (
                      <Cosmetics
                        selectedProducts={selectedProducts}
                        totalProducts={totalProducts}
                        handleSelectAll={handleSelectAll}
                        loading={loading}
                        getPaginatedItems={getPaginatedItems}
                        activeVendor={activeVendor}
                        res={res}
                        flipkartItems={flipkartItems}
                        onCheck={onCheck}
                      />
                    );
                  // Add more cases for other search values and corresponding components
                  default:
                    return null; // Render nothing if no match
                }
              })()}
            </div>

            <div className='w-full flex justify-center pt-1 pb-5'>
              <div className='w-1/4 bg-white text-black items-center flex justify-between rounded-full py-1 shadow-md'>
                <button
                  className='prev-button py-1 px-2 focus:outline-none'
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <i class='fa-solid fa-chevron-left pr-1 pl-1'></i>PREV
                </button>
                <p className='page-count'>
                  {currentPage} | {totalPages}
                </p>
                <button
                  className=' next-button py-1 px-2 focus:outline-none'
                  onClick={handleNextPageClick}
                  disabled={currentPage === totalPages}
                >
                  NEXT<i class='fa-solid fa-chevron-right pl-1'></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{!isProductLoaded ? <Loader /> : null}</>;
  }
};

StaticProdList.propTypes = {
  getProductList2: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  Masala_and_Spices: PropTypes.func.isRequired,
  cosmetics: PropTypes.func.isRequired,
  audio: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.products,
});

export default connect(mapStateToProps, {
  setAlert,
  getProductList2,
  selectedProductUrls,
  Masala_and_Spices,
  cosmetics,
  audio,
})(StaticProdList);
