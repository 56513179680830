/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link, Navigate, useLocation } from 'react-router-dom';

// Components
import HamburgerNavBar from './dropdowns/HamburgerNavBar';

// import inferenceLogo from '../../img/inference.png';

// import logo_sm_color from '../../img/logo_sm_color.png';
import logo_sm_color from '../../img/rn_logo_color_1.png';
import ic_logo_sm_color from '../../img/ic_logo_color.png';
import defaultProfilePic from '../../img/defaultProfilePic.png';
import hamburger_icon from '../../img/hamburger_icon.png';
import utk_profile_pic from '../../img/utk_profile_pic.JPG';

import '../../css/navbar.css';
import ProfilePic from './profile/ProfilePic';

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let Navbar = ({
  auth: { isAuthenticated, user, loading, fullNavBar },
  logout,
}) => {
  const [showManageProfile, setShowManageProfile] = useState(false);

  const authLinks_1 = (
    <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
      {/* <button
        type='button'
        className='bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
      >
        <span className='sr-only'>View notifications</span>
        <BellIcon className='h-6 w-6' aria-hidden='true' />
      </button> */}

      {/* Profile dropdown */}
      <Menu as='div' className='ml-3 relative'>
        <div>
          <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
            <span className='sr-only'>Open user menu</span>
            <img
              className='h-8 w-8 rounded-full'
              src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
              alt=''
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/profile'
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  Your Profile
                </Link>
              )}
            </Menu.Item>
            {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href='!#'
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item> */}
            {showManageProfile ? (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href='/manage-profile'
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    Manage profile
                  </a>
                )}
              </Menu.Item>
            ) : (
              <></>
            )}

            <Menu.Item>
              {({ active }) => (
                <a
                  href='/login'
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                  onClick={logout}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );

  const authLinks_2 = (
    <div className='hidden sm:block sm:ml-6'>
      <div className='flex space-x-4'>
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );

  if (user && !showManageProfile) {
    if (user.super_user) {
      setShowManageProfile(true);
    }
  }

  const location = useLocation();
  const isConverseAIProductsPage =
    location.pathname === '/converse-ai-products';

  return (
    <nav className='bg-transparent py-2 px-8 flex justify-between items-center'>
      {/* Logo and text on the left */}
      <div className='flex items-center'>
        {isConverseAIProductsPage ? (
          <>
            <Link to='/converse-ai-products' className='link-style-navbar'>
              <img
                src={ic_logo_sm_color}
                alt='Converse AI Logo'
                className='w-8 h-8 mr-2'
              />
            </Link>
            <Link to='/converse-ai-products' className='link-style-navbar'>
              iConverse
            </Link>
          </>
        ) : (
          <>
            <Link to='/analysis-type' className='link-style-navbar'>
              <img src={logo_sm_color} alt='Logo' className='w-8 h-8 mr-2' />
            </Link>
            <Link to='/analysis-type' className='link-style-navbar'>
              Review Now
            </Link>
          </>
        )}
      </div>

      {/* Profile picture, user name, and dropdown on the right */}
      <div className='flex items-center'>
        <div className='flex items-center mr-4'>
          <ProfilePic defaultProfilePic={defaultProfilePic} />
          <span className='user-name'>{user.user_name}</span>
        </div>
        <HamburgerNavBar />
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
