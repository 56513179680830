import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SignInAccess from '../layout/SignInAccess';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/rn_logo_color_1.png';
import Loader from '../loader/Loader';
import {
  getUsersList,
  getUserDetails,
  updateUserService,
} from '../../actions/user';
import ManageUserModal from '../layout/modals/ManageUserModal';

let ManageProfile = ({
  auth: { isAuthenticated, user },
  setAlert,
  getUsersList,
  getUserDetails,
  updateUserService,
}) => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [perPageCount, setPerPageCount] = useState(0);
  const [lastPagecount, setLastPageCount] = useState(0);
  const [showManageModal, setShowManageModal] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateObj, setDateObj] = useState({});

  const navigate = useNavigate();

  // const [startDate, setStartDate] = useState(
  //   userDetails.userDetails.app_sDate
  //     ? new Date(userDetails.userDetails.app_sDate)
  //     : null
  // );
  // const [endDate, setEndDate] = useState(
  //   userDetails.userDetails.app_eDate
  //     ? new Date(userDetails.userDetails.app_eDate)
  //     : null
  // );

  // Checkbox Change Handler
  const onCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // console.log(e.target.value);
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    getUsersList(pageNo).then((res) => {
      console.log('user res', res);
      setUserList(res.data.users);
      setTotalUser(res.data.userCount);
      setPerPageCount(res.data.userCountPerPage);
      setLoading(false);
    });
  }, []);

  const nextPage = () => {
    setLoading(true);

    getUsersList(pageNo + 1).then((res) => {
      console.log('user res', res);
      setUserList(res.data.users);
      setPerPageCount(perPageCount + res.data.userCountPerPage);
      setLastPageCount(res.data.userCountPerPage);
      setLoading(false);
    });
    console.log('next page');
    setPageNo(pageNo + 1);
  };

  const prevPage = () => {
    setLoading(true);

    getUsersList(pageNo - 1).then((res) => {
      console.log('user res', res);
      setUserList(res.data.users);
      setPerPageCount(perPageCount - lastPagecount);
      setLoading(false);
    });

    console.log('Prev page');
    setPageNo(pageNo - 1);
  };

  const onSave = (e, stDate, edDate) => {
    // Revert Back the checked Values (only contains if true)
    // Call an API and Update the Value

    // Check if either start date or end date is selected
    if ((stDate && !edDate) || (!stDate && edDate)) {
      alert('Please select both start date and end date.');
      return;
    }

    console.log('values', checkedValues, e.target.value, stDate, edDate);

    updateUserService(checkedValues, e.target.value, stDate, edDate)
      .then((res) => {
        console.log('API return', res);
        if (res.status === 200) {
          toast.success('Updated Successfully');
          // setAlert('Updated Successfully', 'success');
        }
      })
      .then(() => {
        setShowManageModal(false);
      })
      .then(() => {
        window.location.reload(false);
      });
  };

  const onChange = (date, id) => {
    setDateObj({ ...dateObj, [id]: date });

    setSelectedDate(date);
    console.log(date, id);
    console.log('test date obj', dateObj);
  };

  const onClose = (value) => {
    console.log('Close clicked');
    setShowManageModal(value);
  };

  const onManageClick = (e) => {
    e.preventDefault();
    console.log('value at manage button', e.target.value);
    getUserDetails(e.target.value)
      .then((res) => {
        console.log('user profile Details', res.data);
        setUserDetails(res.data);
        var keys = Object.keys(res.data.userDetails);

        var filtered = keys.filter(function (key) {
          return res.data.userDetails[key] === true;
        });
        setCheckedValues([...filtered]);
        console.log('keys', filtered);
      })
      .then(() => {
        setShowManageModal(true);
      });
    // Hit API of user details using user_id  and load the data then set modal to true and bind data
    // setShowManageModal(true);
  };

  // console.log('Page no', userDetails);
  // console.log('checked values', checkedValues);

  console.log('dateFull', selectedDate);
  console.log('date Obj', dateObj);

  if (isAuthenticated && user) {
    if (user.user_isSuperUser) {
      return (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className='min-h-full'>
              <main>
                <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
                  <div className='px-4 py-6 sm:px-0'>
                    <div>
                      <Link to='/analysis-type'>
                        <img
                          className='h-10 w-auto mb-4'
                          src={inferenceLogo}
                          alt='Workflow'
                        />
                      </Link>
                    </div>
                    <div className='text-center mb-3 text-lg font-medium tracking-wide'>
                      <h1>Manage User Profile</h1>
                    </div>
                    <div className='mb-2 overflow-x-auto relative shadow-md sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='py-3 px-6'>
                              Name
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              Company
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              Mobile
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              Email
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              Start Date
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              End Date
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              User Type
                            </th>
                            <th scope='col' className='py-3 px-6'>
                              <span className='sr-only'>Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {' '}
                          {!loading ? (
                            userList.map((user) => (
                              <tr className='bg-white-900 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-blue-100 dark:hover:bg-gray-600'>
                                <th
                                  scope='row'
                                  className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                >
                                  {/* {user.name} */}
                                  {user.user_name.charAt(0).toUpperCase() +
                                    user.user_name.slice(1)}
                                </th>
                                <td className='py-4 px-6'>
                                  {user.user_company}
                                </td>
                                <td className='py-4 px-6'>
                                  {user.user_mobile_number}
                                </td>
                                <td className='py-4 px-6'>{user.user_email}</td>
                                <td className='py-4 px-6'>
                                  {user.app_sDate
                                    ? new Date(
                                        user.app_sDate
                                      ).toLocaleDateString()
                                    : ''}
                                </td>
                                <td className='py-4 px-6'>
                                  {user.app_eDate
                                    ? new Date(
                                        user.app_eDate
                                      ).toLocaleDateString()
                                    : ''}
                                </td>
                                <td className='py-4 px-6'>
                                  {user.user_isSuperUser ? 'Admin' : 'Standard'}
                                </td>
                                <td className='py-4 px-6 text-right'>
                                  <button
                                    className='font-medium text-blue-600 dark:text-blue-500 hover:underline border:none outline-0 focus:outline-0'
                                    // onClick={() => {
                                    //   onManage(true);
                                    // }}
                                    onClick={(e) => {
                                      onManageClick(e);
                                    }}
                                    value={user.user_id}
                                  >
                                    Manage
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='flex justify-end'>
                      <button
                        className='manage-user-prev-btn inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-400 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:cursor-not-allowed'
                        disabled={pageNo === 1}
                        onClick={prevPage}
                      >
                        <svg
                          aria-hidden='true'
                          className='mr-2 w-5 h-5'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        Previous
                      </button>
                      <button
                        className='manage-user-next-btn inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:cursor-not-allowed'
                        onClick={nextPage}
                        disabled={perPageCount === totalUser}
                      >
                        Next
                        <svg
                          aria-hidden='true'
                          className='ml-2 w-5 h-5'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                {showManageModal ? (
                  <ManageUserModal
                    show={true}
                    // onManage={onManage}
                    userDetails={userDetails}
                    onCheck={onCheck}
                    checkedValues={checkedValues}
                    onClose={onClose}
                    onSave={onSave}
                    onChange={onChange}
                    selectedDate={selectedDate}
                    dateObj={dateObj}
                  ></ManageUserModal>
                ) : (
                  <></>
                )}
              </main>
            </div>
          )}
        </>
      );
    }
    return <>{navigate('/page-not-found')}</>;
  } else if (!isAuthenticated) {
    // setAlert('To Access dashboard please register and login first', 'danger');
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  getUsersList,
  getUserDetails,
  updateUserService,
})(ManageProfile);
