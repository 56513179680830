import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Loader from '../loader/Loader';
import inferenceLogo from '../../img/inference.png';
import SignInAccess from '../layout/SignInAccess';
import { setAlert } from '../../actions/alert';
import TrialExpired from './modals/TrialExpired';
import Navbar from '../layout/Navbar';
import '../../css/otpScreen.css';
import '../../css/aiProducts.css';

// Images Imports
import review_now_prod_image from '../../img/review_now_prod_image.png';
import price_now_prod_image from '../../img/price_now_prod_image.png';
import email_sentiment_prod_image from '../../img/email_sentiment_prod_image.png';
import agent_customer_prod_image from '../../img/agent_customer_prod_image.png';
import feedback_analysis_prod_image from '../../img/feedback_analysis_prod_image.png';

// Import Data

import power_bi_dashboards_link from '../../data/powerBiDashboards.json';

const AiProducts = ({ auth: { isAuthenticated, user, isModal }, setAlert }) => {
  const [toggleState, setToggleState] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [activeTab, setActiveTab] = useState('Review Now');
  const [userVerified, setUserVerified] = useState(true);
  const [isToastShown, setIsToastShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (isModal) {
      setModalState(true);
    }

    const handleScroll = () => {
      const middleOffset = window.innerHeight / 2; // Middle of the window height
      const reviewNowDiv = document.getElementById('review-now');
      const priceNowDiv = document.getElementById('price-now');
      const emailSentimentAnalysisDiv = document.getElementById(
        'email-sentiment-analysis'
      );
      const agentCustomerDiv = document.getElementById('agent-customer');
      const feedbackAnalysisDiv = document.getElementById('feedback-analysis');

      const reviewNowOffsetTop = reviewNowDiv.offsetTop;
      const priceNowOffsetTop = priceNowDiv.offsetTop;
      const emailSentimentAnalysisOffsetTop =
        emailSentimentAnalysisDiv.offsetTop;
      const agentCustomerOffsetTop = agentCustomerDiv.offsetTop;
      const feedbackAnalysisOffsetTop = feedbackAnalysisDiv.offsetTop;

      const scrollPosition = window.pageYOffset;

      if (
        scrollPosition >= reviewNowOffsetTop - middleOffset &&
        scrollPosition < priceNowOffsetTop - middleOffset
      ) {
        setActiveTab('Review Now');
      } else if (
        scrollPosition >= priceNowOffsetTop - middleOffset &&
        scrollPosition < emailSentimentAnalysisOffsetTop - middleOffset
      ) {
        setActiveTab('Price Now');
      } else if (
        scrollPosition >= emailSentimentAnalysisOffsetTop - middleOffset &&
        scrollPosition < agentCustomerOffsetTop - middleOffset
      ) {
        setActiveTab('Email Sentiment Analysis');
      } else if (
        scrollPosition >= agentCustomerOffsetTop - middleOffset &&
        scrollPosition < feedbackAnalysisOffsetTop - middleOffset
      ) {
        setActiveTab('Agent Customer Analysis');
      } else if (scrollPosition >= feedbackAnalysisOffsetTop - middleOffset) {
        setActiveTab('Feedback Analysis');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isModal]);

  useEffect(() => {
    const storedIsToastShown = localStorage.getItem('isToastShown');

    if (isAuthenticated && user) {
      if (user.verified && !isToastShown && storedIsToastShown !== 'true') {
        toast.success('Logged in Successfully');
        setIsToastShown(true);
        localStorage.setItem('isToastShown', 'true');
      }
    }
  }, [isAuthenticated, user, isToastShown]);

  const changeTab = (index) => {
    setToggleState(index);
    console.log(toggleState);
  };

  let handleShow = () => {
    setModalState(false);
  };

  const handleTabClick = (event, tabName, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    targetElement.scrollIntoView({ behavior: 'smooth' });
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (user) {
    console.log('isTrial', user.isTrial, user.isPaid);
  }

  // if (isAuthenticated && user) {
  //   if (!user.verified) {
  //     toast.warn('OTP Verification is Pending');
  //     return <Navigate to='/otp-verification' />;
  //   }
  // }

  if (isAuthenticated && user) {
    if (user.user_isVerified) {
      return (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div
                className='fixed top-0 w-full z-10 gradient-bg'
                style={{ maxWidth: '1536px' }}
              >
                <Navbar />
                <div className='tab-bar-first-div rounded-tl-full rounded-br-full'>
                  <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='sm:flex sm:items-center sm:justify-center'>
                      <div className='overflow-hidden'>
                        <div>
                          <nav className='-mb-px flex' aria-label='Tabs'>
                            <div className='flex flex-col items-center'>
                              <Link
                                to='#'
                                onClick={(event) =>
                                  handleTabClick(
                                    event,
                                    'Review Now',
                                    'review-now'
                                  )
                                }
                                className={`${
                                  activeTab === 'Review Now'
                                    ? 'tab-bar-buttons text-white focus:outline-none hover:no-underline'
                                    : 'border-transparent text-white hover:text-gray-700 focus:outline-none hover:no-underline'
                                } whitespace-nowrap pt-3 px-2 mb-1 font-medium text-sm ring-0 `}
                              >
                                Review Now
                              </Link>
                              {activeTab === 'Review Now' && (
                                <div className='button-underline-div w-3/5'></div>
                              )}
                            </div>
                            <div className='flex flex-col items-center'>
                              <Link
                                to='#'
                                onClick={(event) =>
                                  handleTabClick(
                                    event,
                                    'Price Now',
                                    'price-now'
                                  )
                                }
                                className={`${
                                  activeTab === 'Price Now'
                                    ? 'tab-bar-buttons text-white focus:outline-0 hover:no-underline'
                                    : 'border-transparent text-white hover:text-gray-700 focus:outline-none hover:no-underline'
                                } whitespace-nowrap pt-3 px-2 mb-1  font-medium text-sm`}
                              >
                                Price Now
                              </Link>
                              {activeTab === 'Price Now' && (
                                <div className='button-underline-div w-3/5'></div>
                              )}
                            </div>
                            <div className='flex flex-col items-center'>
                              <Link
                                to='#'
                                onClick={(event) =>
                                  handleTabClick(
                                    event,
                                    'Email Sentiment Analysis',
                                    'email-sentiment-analysis'
                                  )
                                }
                                className={`${
                                  activeTab === 'Email Sentiment Analysis'
                                    ? 'tab-bar-buttons text-white focus:outline-0 hover:no-underline'
                                    : 'border-transparent text-white hover:text-gray-700 focus:outline-none hover:no-underline'
                                } whitespace-nowrap pt-3 px-2 mb-1  font-medium text-sm`}
                              >
                                Email Sentiment Analysis
                              </Link>
                              {activeTab === 'Email Sentiment Analysis' && (
                                <div className='button-underline-div w-3/5'></div>
                              )}
                            </div>
                            <div className='flex flex-col items-center'>
                              <Link
                                to='#'
                                onClick={(event) =>
                                  handleTabClick(
                                    event,
                                    'Agent Customer Analysis',
                                    'agent-customer'
                                  )
                                }
                                className={`${
                                  activeTab === 'Agent Customer Analysis'
                                    ? 'tab-bar-buttons text-white focus:outline-0 hover:no-underline'
                                    : 'border-transparent text-white hover:text-gray-700 focus:outline-none hover:no-underline'
                                } whitespace-nowrap pt-3 px-2 mb-1  font-medium text-sm`}
                              >
                                Agent Customer Analysis
                              </Link>
                              {activeTab === 'Agent Customer Analysis' && (
                                <div className='button-underline-div w-3/5'></div>
                              )}
                            </div>
                            <div className='flex flex-col items-center'>
                              <Link
                                to='#'
                                onClick={(event) =>
                                  handleTabClick(
                                    event,
                                    'Feedback Analysis',
                                    'feedback-analysis'
                                  )
                                }
                                className={`${
                                  activeTab === 'Feedback Analysis'
                                    ? 'tab-bar-buttons text-white focus:outline-0 hover:no-underline'
                                    : 'border-transparent text-white hover:text-gray-700 focus:outline-none hover:no-underline'
                                } whitespace-nowrap pt-3 px-2 mb-1 font-medium text-sm`}
                              >
                                Feedback Analysis
                              </Link>
                              {activeTab === 'Feedback Analysis' && (
                                <div className='button-underline-div w-3/5'></div>
                              )}
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='page-content gradient-bg'
                style={{ marginTop: '99px' }}
              >
                <div className='grid grid-cols-2 h-auto pt-4' id='review-now'>
                  <div className=' p-4 flex items-center justify-center'>
                    <div>
                      <img
                        className='object-contain review-now-prod-image'
                        src={review_now_prod_image}
                        alt='Prod Review Now'
                      />
                    </div>
                  </div>
                  <div className='p-4 flex justify-end items-center'>
                    <div className='text-left w-96 mr-4'>
                      <h2 className='review-now-heading'>Review now</h2>
                      <div className='review-now-heading-underline'></div>
                      <p className='review-now-description'>
                        Use AI to analyze verified REVIEW of yours brand and
                        competitors to understand your customer preferences,
                        sentiments, identify revelant topics and keywords
                      </p>
                      <Link
                        to='/analysis-type'
                        className='review-now-button inline-block hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                      >
                        Try Now
                        <i class='fa-solid fa-chevron-right ml-2'></i>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-2 h-auto pt-4' id='price-now'>
                  <div className='p-4 flex justify-start items-center'>
                    <div className='text-left w-96 ml-4'>
                      <h2 className='price-now-heading'>Price now</h2>
                      <div className='price-now-heading-underline'></div>
                      <p className='price-now-description'>
                        Use real time PRICE alerts, analysis, recommendations
                        ans stock alerts for eCommerce companies to achieve
                        increased revenue, profits and business efficiency
                      </p>
                      <Link
                        to=''
                        className='price-now-button inline-block hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                        // disabled
                        onClick={() =>
                          openInNewTab(power_bi_dashboards_link.priceNow.link)
                        }
                      >
                        Try Now
                        <i class='fa-solid fa-chevron-right ml-2'></i>
                      </Link>
                    </div>
                  </div>
                  <div className=' p-4 flex items-center justify-center'>
                    <div>
                      <img
                        className='object-contain price-now-prod-image'
                        src={price_now_prod_image}
                        alt='Price Now'
                      />
                    </div>
                  </div>
                </div>

                <div
                  className='grid grid-cols-2 h-auto pt-4'
                  id='email-sentiment-analysis'
                >
                  <div className=' p-4 flex items-center'>
                    <div>
                      <img
                        className='object-contain email-sentiment-prod-image'
                        src={email_sentiment_prod_image}
                        alt='Email Sentiment Analysis'
                      />
                    </div>
                  </div>
                  <div className='p-4 flex justify-end items-center'>
                    <div className='text-left w-96 mr-4'>
                      <h2 className='email-sentiment-heading'>
                        Email Sentiment Analysis
                      </h2>
                      <div className='email-sentiment-heading-underline'></div>
                      <p className='email-sentiment-description'>
                        Our Email Sentiment Analysis Engine uses Natural
                        Language Processing to classify your emails as positive,
                        negative or neutral
                      </p>
                      <Link
                        to=''
                        className='email-sentiment-button inline-block hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                        // disabled
                        onClick={() =>
                          openInNewTab(
                            power_bi_dashboards_link.emailSentimentAnalysis.link
                          )
                        }
                      >
                        Try Now
                        <i class='fa-solid fa-chevron-right ml-2'></i>
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className='grid grid-cols-2 h-auto pt-4'
                  id='agent-customer'
                >
                  <div className='p-4 flex justify-start items-center'>
                    <div className='text-left w-96 ml-4'>
                      <h2 className='agent-customer-heading'>
                        Agent Customer CX
                      </h2>
                      <div className='agent-customer-heading-underline'></div>
                      <p className='agent-customer-description'>
                        Agent customer analysis is a customer service approach
                        that utilizes our data-driven methods and NLP (Natural
                        Language Processing) technology to extract valuable
                        insights from contact center interactions.
                      </p>
                      <Link
                        to=''
                        className='agent-customer-button inline-block hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                        // disabled
                        onClick={() =>
                          openInNewTab(
                            power_bi_dashboards_link.agentCustomerCX.link
                          )
                        }
                      >
                        Try Now
                        <i class='fa-solid fa-chevron-right ml-2'></i>
                      </Link>
                    </div>
                  </div>
                  <div className=' p-4 flex items-center justify-center'>
                    <div>
                      <img
                        className='object-contain agent-customer-prod-image'
                        src={agent_customer_prod_image}
                        alt='Agent Customer'
                      />
                    </div>
                  </div>
                </div>

                <div
                  className='grid grid-cols-2 h-auto py-4'
                  id='feedback-analysis'
                >
                  <div className=' p-4 flex items-center'>
                    <div>
                      <img
                        className='object-contain feedback-analysis-prod-image'
                        src={feedback_analysis_prod_image}
                        alt='Feedback Analysis'
                      />
                    </div>
                  </div>
                  <div className='p-4 flex justify-end items-center'>
                    <div className='text-left w-96 mr-4'>
                      <h2 className='feedback-analysis-heading'>
                        Feedback Analysis
                      </h2>
                      <div className='feedback-analysis-heading-underline'></div>
                      <p className='feedback-analysis-description'>
                        Our Email Sentiment Analysis Engine uses Natural
                        Language Processing to classify your emails as positive,
                        negative or neutral
                      </p>
                      <Link
                        to=''
                        className='feedback-analysis-button inline-block'
                        disabled
                      >
                        Try Now
                        <i class='fa-solid fa-chevron-right ml-2'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    //  else if (!user.isTrial && !user.isPaid) {
    //   return <TrialExpired></TrialExpired>;
    // }
  } else if (!isAuthenticated) {
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(AiProducts);
