import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../loader/Loader';

import '../../css/page404.css';

// Omport Images
import bg_1_404 from '../../img/404_bg_1.png';
import bg_3_404 from '../../img/404_bg_3.png';
import logo_sm_color from '../../img/ic_logo_color.png';
import review_now_logo_color from '../../img/review_now_logo_color.png';
import vecotr_404 from '../../img/vector_404.png';

const Page404 = (props) => {
  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <div className={`min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow justify-center'>
            <div className='flex flex-col items-center'>
              <img src={vecotr_404} alt='404' className='bg-404' />
              <div className='container max-w-xs'>
                <h1 className='heading-404'>Page not found!</h1>

                <p className='secondary-text-404'>
                  We're sorry, the page you requested could not be found. Please
                  go back to the home page.
                </p>
                <Link to='/analysis-type' className='hover:no-underline'>
                  <button className='button-404 mx-auto group relative flex justify-center items-center border border-transparent hover:bg-hover_1-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider'>
                    <i className='fas fa-arrow-left mr-2'></i>Back to home
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Page404;
