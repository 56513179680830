/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { setAlert } from '../../actions/alert';
import inferenceLogo from '../../img/inference.png';
import SignInAccess from '../layout/SignInAccess';
import Loader from '../loader/Loader';
import axios from 'axios';

// Component Import
import Navbar from '../layout/Navbar';
import ReportsDropdown from '../layout/dropdowns/ReportsDropdown';

// CSS Import
import '../../css/aiProducts.css';
import '../../css/reports.css';

// Data import
// import interactiveReports from '../../data/interactiveReports.json';

let Reports = ({ auth: { isAuthenticated, user }, setAlert }) => {
  const [iframe, setIframe] = useState({
    url: 'https://app.powerbi.com/view?r=eyJrIjoiOTQwMGM0ZjUtNWI3MS00NGIxLWIyNDUtYjEzYWRjNDk4OWEwIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9',
    title: `Zoff Demo Dashboard (26 sept)`,
  });
  const [activeTab, setActiveTab] = useState('Product Review');
  const [loading, setLoading] = useState(true);
  const [selectedInputText, setSelectedInputText] = useState(null);
  const [search_value, setSearchValue] = useState(
    localStorage.getItem('search_value') || 'default_search_value'
  );

  const [interactiveReports, setInteractiveReports] = useState(null);

  useEffect(() => {
    const fetchIFramesMaster = async () => {
      try {
        console.log('Search value  ajdas', search_value);
        // Make an API call to fetch platform-specific values
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/review-now/api/i-frame-mastet-list?product_name=${search_value}`
        );

        if (response.data) {
          // Set the platformValues state with the fetched data
          // console.log(
          //   'response from api ashdif',
          //   response.data.records[0].iframes
          // );
          setInteractiveReports(response.data.records[0].iframes);
          // Filter the initially selected platforms (selected: 1)
          const filterData = response.data.records[0].iframes.find(
            (iframe) => iframe.selected === 1
          );

          if (filterData) {
            setSelectedInputText(filterData);
          }
        }
      } catch (error) {
        console.error(error);
        // Handle the error as needed
      }
    };

    setTimeout(() => {
      fetchIFramesMaster().then(() => {
        setLoading(false);
      });
    }, 10000);
    // fetchIFramesMaster();
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // }, []);

  if (selectedInputText !== null) {
    console.log('selectedplatform gggg', selectedInputText);
  } else {
    console.log('still receiving data');
  }

  const handleTabClick = (event, tabName, targetId, method) => {
    event.preventDefault();
    setActiveTab(tabName);

    // Execute the method passed as a parameter
    if (typeof method === 'function') {
      method();
    }
  };

  // CSAT IFRAME
  const csat = () => {
    setIframe({
      url: 'https://app.powerbi.com/view?r=eyJrIjoiNDU1YWQyODAtY2RhYS00NzUyLThkMWQtZDQ3YWUxMDc4NTBlIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9&pageName=ReportSection0f4c71c33a4c4f7712f1',
      title: 'Report Section',
    });
  };

  // NPS
  // const nps = () => {
  //   setIframe({
  //     url: 'https://app.powerbi.com/view?r=eyJrIjoiNDU1YWQyODAtY2RhYS00NzUyLThkMWQtZDQ3YWUxMDc4NTBlIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9',
  //     title: 'Report Section',
  //   });
  // };

  // CompetitorReview || Industry Analysis
  const competitorReview = () => {
    setIframe({
      url: 'https://app.powerbi.com/view?r=eyJrIjoiMzUxNDI0MmQtNjM5ZC00MTgyLWEyZGUtYzc3ZGM3NzBlODg5IiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9',
      title: 'Competitors Analysis',
    });
  };

  // Product Review
  const productReview = () => {
    setIframe({
      url: 'https://app.powerbi.com/view?r=eyJrIjoiOTQwMGM0ZjUtNWI3MS00NGIxLWIyNDUtYjEzYWRjNDk4OWEwIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9',
      title: `Zoff Demo Dashboard (26 sept)`,
    });
  };

  // console.log(
  //   'select report',
  //   selectedInputText.value,
  //   selectedInputText.dashbordLink
  // );
  if (isAuthenticated && user) {
    return (
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div className='min-h-screen flex flex-col backround-gradient-reports'>
            <Navbar></Navbar>
            <div>
              <div className='w-11/12 mx-auto flex items-center justify-between mt-2'>
                <div className='interactive-reports flex items-center'>
                  <h2 style={{ display: 'inline' }}>Interactive Analytics</h2>
                  {interactiveReports !== null ? (
                    <ReportsDropdown
                      searchInputValues={interactiveReports}
                      selected={selectedInputText}
                      setSelected={setSelectedInputText}
                    ></ReportsDropdown>
                  ) : (
                    ''
                  )}
                </div>

                <div>
                  <Link
                    to='/dashboard'
                    className='search-new-product-link-button hover:ring-1 hover:ring-offset-1 hover:ring-primary_1-10'
                  >
                    <i class='fa-brands fa-searchengin'></i> Search New Product
                  </Link>
                </div>
              </div>
            </div>
            <div className='flex justify-center pb-5'>
              <div className='flex w-auto power-bi-report-frame shadow-lg'>
                <div className='grid h-auto p-2'>
                  {selectedInputText && selectedInputText.dashbordLink && (
                    <iframe
                      title={iframe.title}
                      width='1150'
                      height='486'
                      src={selectedInputText.dashbordLink}
                      frameBorder='0'
                      allowFullScreen={true}
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  } else if (!isAuthenticated) {
    return (
      <SignInAccess
        inferenceLogo={inferenceLogo}
        loading={loading}
      ></SignInAccess>
    );
  } else {
    return <>{loading ? <Loader /> : null}</>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(Reports);
