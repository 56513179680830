import React from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../loader/Loader';
import Navbar from '../Navbar';

//CSS
import '../../../css/otherComponents/about.css';

// Images
import review_now_logo_white from '../../../img/review_now_logo_white.png';
import about_intro_image from '../../../img/about_intro_image.png';
import about_intro_image_2 from '../../../img/about_intro_image_2.png';
// import about_intro_image_3 from '../../../img/about_intro_imgae_3.png';

const About = () => {
  return (
    <div className='w-full'>
      {/* Top Section */}
      <div className='about-top-div'>
        <div className='flex justify-between items-center pt-1'>
          <div className='flex items-center pl-2'>
            <img
              src={review_now_logo_white}
              alt='Logo'
              className='navbar-logo'
            />
            <Link
              to='/login'
              className='ml-2 about-text-logo hover:no-underline'
            >
              Review Now
            </Link>
          </div>
          <div className='space-x-4'>
            <Link
              to='/about-us'
              className='about-about-link hover:no-underline'
            >
              About us
            </Link>
            <Link
              to='/contact-us'
              className='about-contact-link pr-2 hover:no-underline'
            >
              Contact
            </Link>
          </div>
        </div>

        {/* Content Section */}
        <div className='w-full flex justify-center items-center flex-col  about-heading-div'>
          <div className='w-full'>{/* Your existing content goes here */}</div>
          <div className='w-full text-center mt-4'>
            <h1 className='about-heading-1'>Company Profile</h1>
            <p className='about-heading-2'>About us</p>
          </div>
        </div>
      </div>

      {/* About Inference content */}
      <div className='w-full flex justify-center items-center flex-col'>
        <div className='w-full'>{/* Your existing content goes here */}</div>
        <div className='w-full text-center mt-4'>
          <h1 className='about-heading-3'>Inference Labs</h1>
          <p className='about-inference-text ml-56 mr-56 mt-8'>
            Inference Labs is a cloud-based AI company that helps B2B businesses
            make better decisions by analysing unstructured data, such as
            customer reviews, feedback forms, voice data, and app data. We use
            AI to automatically extract key insights from this data, such as
            customer sentiment, product feedback, and market trends.
          </p>
          <p className='about-inference-text ml-56 mr-56 mt-8'>
            Our insights are comprehensive, real-time, and actionable, so you
            can use them to optimize your products, services, and marketing
            strategies
          </p>
        </div>
      </div>

      {/* Additional Content Section (Two Columns) */}
      <div className='w-full flex justify-between items-center px-16 mt-20'>
        <div className='flex-shrink-0'>
          <img
            src={about_intro_image}
            alt='Description of the image'
            className='about-intro-image' // Adjust width and height as needed
          />
        </div>
        <div className='w-full md:flex-grow pr-8'>
          <div className='flex justify-left mb-4 mt-20'>
            <h1 className='about-analytics-text pl-2'>Our Product</h1>
          </div>
          <div>
            <p className='about-content-paragraphs pb-2'>
              Review Now is a next generation AI technology that helps
              businesses make better decisions faster by automating the analysis
              of reviews from verified buyers from online platforms like Amazon,
              Nykaa, Flipkart, others, D2C and Mobile Platforms.
            </p>

            <p className='about-content-paragraphs pb-8'>
              Unlike traditional review analysis tools, Review Now does not
              require any manual intervention or training. It uses AI to
              automatically identify and extract key insights from customer
              reviews, including sentiment, features mentioned, and topics
              discussed.
            </p>

            <p className='about-content-paragraphs pb-8'>
              Review Now provides businesses with the most comprehensive,
              objective, and accurate view of their products and services. It
              can be used to:
            </p>

            {/* Bullet points */}
            <ul className='about-bullet-points pb-8'>
              <li>Understand customer needs and preferences</li>
              <li>Identify product and service strengths and weaknesses</li>
              <li>Benchmark against competitors</li>
              <li>Develop new products and services</li>
              <li>Create personalized marketing campaigns</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Additional Content Section (Reversed Columns) */}
      <div className='w-full flex justify-between items-center px-16 mt-28'>
        <div className='w-full md:flex-grow pr-8 pl-4'>
          <div>
            <p className='about-content-paragraphs pb-2'>
              Review Now is a powerful AI tool that can help businesses improve
              their products, services, and marketing strategies like:
            </p>

            <p className='about-content-paragraphs pb-8'>
              A <b>product development</b> team can use Review Now to identify
              the most important features to customers and the areas where
              customers are having problems. This information can be used to
              improve existing products and develop new ones that are more
              likely to be successful.
            </p>

            <p className='about-content-paragraphs pb-8'>
              A <b>marketing team</b> can use Review Now to create personalized
              marketing campaigns that target specific customer segments. For
              example, they can use Review Now to identify customers who have
              expressed interest in a particular feature and then send them
              targeted messages about that feature.
            </p>

            <p className='about-content-paragraphs pb-8'>
              A <b>sales team</b> can use Review Now to identify and engage with
              potential customers who are interested in their products or
              services. For example, they can use Review Now to find customers
              who have left positive reviews of similar products or services
            </p>
          </div>
        </div>
        <div className='flex-shrink-0'>
          <img
            src={about_intro_image_2}
            alt='Description of the image'
            className='about-intro-image-2' // Adjust width and height as needed
          />
        </div>
      </div>

      {/* Additional Content Section (New Div) */}
      <div className='w-full mt-20 ml-20 about-contact-div-main'>
        <div className='bg-cover bg-center  rounded-lg about-contact-div'>
          <div className='flex flex-col justify-center items-start h-full px-8'>
            <h2 className='mt-16 about-contact-heading-1'>
              Let's build <br /> Something together
            </h2>
            <p className='about-contact-paragraph-1 mt-6 '>
              We are always there to fine-tune your process by developing a
              custom framework by <br /> implementing such amazing and accurate
              problem-solving techniques in your organization.
            </p>
            <Link
              to='/contact-us'
              className='about-contact-button py-2 px-4 mt-6 mb-16 flex items-center focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 focus:outline-none'
            >
              Contact us <i className='fa-solid fa-arrow-right ml-2'></i>
            </Link>
          </div>
        </div>
      </div>
      <div className='w-full h-2'></div>
    </div>
  );
};

export default About;
