import React from 'react';

// Image Import
import logo_sm_color from '../../../img/rn_logo_color_1.png';
import best_seller_icon from '../../../img/best_seller_icon.png';
import sentiment_score_icon from '../../../img/sentiment_score_icon.png';
import star_rating_icon from '../../../img/star_rating_icon.png';
import verified_review_icon from '../../../img/verified_review_icon.png';

// CSS Import
import '../../../css/modals/productKeyMetricsModal.css';

const ProductKeyMetricsModal = ({
  selectedProduct,
  setShowModal,
  showModal,
}) => {
  console.log('selectedProduct', selectedProduct);
  return (
    <>
      {/* Background overlay */}
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-50 z-50 ${
          showModal ? '' : 'hidden'
        }`}
        onClick={() => {
          setShowModal(false);
        }}
      ></div>

      {/* Modal */}
      <div
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-50 w-3/4 md:w-4/6 lg:w-3/5 xl:w-3/5 h-auto md:h-5/6 lg:auto xl:auto `}
      >
        {/* Close button */}
        <button
          className='absolute top-3 right-3 p-1 hover:bg-gray-200 rounded-full'
          onClick={() => {
            setShowModal(false);
          }}
        >
          <i class='fa-regular fa-circle-xmark'></i>
        </button>

        {/* Content */}
        <div className='p-8 h-auto'>
          <div
            className='grid grid-cols-2 gap-8 h-auto'
            style={{ gridTemplateColumns: '30% 70%' }}
          >
            {/* Product image */}
            <div className='flex justify-center w-full'>
              <div
                className='flex items-center'
                style={{ width: '200px', height: '200px' }}
              >
                <img
                  src={selectedProduct.prod_image}
                  alt='Product-img'
                  className='w-full h-full object-contain rounded-lg shadow-md'
                  style={{ objectFit: 'contain' }}
                />
              </div>
            </div>

            {/* Product details */}
            <div className='flex justify-center pr-5'>
              <div className=' flex items-center'>
                <div className='text-left'>
                  <div>
                    <h3 className='brand-heading'>
                      Brand:{' '}
                      <span className='brand-heading-Value'>
                        {selectedProduct.brand}
                      </span>
                    </h3>
                    <p className='product-name'>Product Name:</p>
                    <p className='product-name-description text-justify'>
                      {selectedProduct.prod_name}
                    </p>
                  </div>
                  <div className='flex items-center'>
                    <button className='category-and-industry mr-4 button-bg-gradient focus:outline-none outline-none'>
                      Category: <span>{selectedProduct.category}</span>
                    </button>
                    <button className='category-and-industry button-bg-gradient focus:outline-none outline-none'>
                      Industry: <span>{selectedProduct.industry}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='text-left mt-3 mb-1 '>
            <p className='key-metrics-heading'>Key Metrics</p>
          </div>

          {/* Metrics */}
          <div className='grid grid-cols-2 gap-8 pb-4'>
            {/* Metric 1 */}
            <div className='bg-gray-100 rounded-lg p-3 drop-shadow-lg flex items-center justify-center best-seller-rank-box-shadow'>
              <img
                src={best_seller_icon}
                alt='icon'
                className='w-12 h-12 mr-4'
              />
              <div className='flex flex-col'>
                <div className='key-metrics-heading'>Best Seller Rank</div>
                <div className='key-metrics-value'>
                  {selectedProduct.bestSellerRank}
                  <span className='text-sm align-text-bottom'>th</span>
                </div>
              </div>
            </div>

            {/* Metric 2 */}
            <div className='bg-gray-100 rounded-lg p-3 drop-shadow-lg flex items-center justify-center sentiment-score-box-shadow'>
              <img
                src={sentiment_score_icon}
                alt='icon'
                className='w-12 h-12 mr-4'
              />
              <div className='flex flex-col'>
                <div className='key-metrics-heading'>Sentiment Score</div>
                <div className='key-metrics-value'>
                  {selectedProduct.sentimentScore}
                  <span className='text-sm align-text-bottom'>%</span>
                </div>
              </div>
            </div>

            {/* Metric 3 */}
            <div className='bg-gray-100 rounded-lg p-3 drop-shadow-lg flex items-center justify-center star-rating-rank-box-shadow'>
              <img
                src={star_rating_icon}
                alt='icon'
                className='w-12 h-12 mr-4'
              />
              <div className='flex flex-col'>
                <div className='key-metrics-heading'>Star Rating</div>
                <div className='key-metrics-value'>
                  {selectedProduct.startRating}
                </div>
              </div>
            </div>

            {/* Metric 4 */}
            <div className='bg-gray-100 rounded-lg p-3 drop-shadow-lg flex items-center justify-center verified-reviews-box-shadow'>
              <img
                src={verified_review_icon}
                alt='icon'
                className='w-12 h-12 mr-4'
              />
              <div className='flex flex-col'>
                <div className='key-metrics-heading'>Verified Reviews</div>
                <div className='key-metrics-value'>
                  {selectedProduct.verifiedReviews}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductKeyMetricsModal;
