import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
const { DateTime } = require('luxon');

const ServiceList = ({
  name,
  value,
  id,
  onCheck,
  checkedValues,
  selectedDate,
  onChange,
  dateObj,
  userDetails,
}) => {
  const [dateExpiryString, setDateExpiryString] = useState(`${id}_expiry`);
  const [dateExp, setDateExp] = useState(
    userDetails.user_details[dateExpiryString]
  );

  // useEffect(() => {
  //   // setDateExp(new Date(userDetails.user_details[dateExpiryString]));
  // }, []);
  // const [selectedDate, setSelectedDate] = useState(null);
  // const [dateObj, setDateObj] = useState({});

  // const onChange = (date, id) => {
  //   setDateObj({ ...dateObj, [id]: date });

  //   setSelectedDate(date);
  //   console.log(date, id);
  // };

  // console.log('++', `${id}_expiry`);
  // console.log('dateExpiryString', dateExpiryString);
  // console.log('--', userDetails.user_details[dateExpiryString]);
  // console.log('dateExp', new Date(dateExp));
  if (dateExp) {
    return (
      <div className='row mx-2 text-sm border-b'>
        <div className='col-6 p-0'>
          <span className='inline-block align-middle'>{name}</span>
        </div>
        <div className='col-2 p-0 content-center'>
          <label
            for={value}
            class='inline-flex relative items-center mt-1.5 cursor-pointer'
          >
            <input
              type='checkbox'
              value={value}
              id={id}
              class='sr-only peer'
              checked={checkedValues.includes(value)}
              onClick={(e) => onCheck(e)}
            />
            <div class="w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 py-auto"></div>
          </label>
        </div>
        <div className='col-4 p-0'>
          {/* <span className='inline-block align-middle'>Expire Date Picker</span> */}
          <div>
            <DatePicker
              // selected={dateObj[id]}
              selected={
                checkedValues.includes(id)
                  ? dateObj[id] && dateExp
                    ? new Date(dateObj[id])
                    : new Date(dateExp)
                  : ''
              }
              dateFormat='MM-dd-y'
              // dateFormat='dd/MM/y'
              onChange={(date) => onChange(date, id)}
              className={
                'w-32 h-4 border-grey-50 rounded-lg text-xs disabled:cursor-not-allowed disabled:opacity-50 '
              }
              placeholderText='Select date'
              disabled={!checkedValues.includes(id)}
              // minDate={new Date()}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ServiceList;
