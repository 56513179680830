import { Link, Navigate } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import PropTypes from 'prop-types';
import '../../css/auth/register.css';
import '../../css/global.css';
// import axios from 'axios';

// Images import
import review_now_logo_color from '../../img/review_now_logo_color.png';
import background from '../../img/background.png';

let Register = ({ setAlert, register, isOtpVerification }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    mobile: '',
    company: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  // Destructure formData
  const { name, email, password, password2, mobile, company } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, password, mobile, company);
    if (password !== password2) {
      // setAlert('Password does not match', 'danger');
      toast.error('Confirm password does not matches!');
      console.log('Password does not matches');
    } else {
      setLoading(true);
      register({
        name,
        email,
        password,
        mobile,
        company,
      })
        .then((e) => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
      console.log('Success');
    }
  };

  // Redirect if logged in
  if (isOtpVerification && localStorage.getItem('token')) {
    return <Navigate to='/otp-verification' replace={true} />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`${background} min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow'>
            {/* Left Area */}
            <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
              <div className='data w-4/5 flex flex-col items-center justify-center bg-white rounded-lg p-8'>
                <h2 className='form-heading'>Register</h2>
                <form
                  className='w-full'
                  onSubmit={(e) => onSubmit(e)}
                  method='POST'
                >
                  <input type='hidden' name='remember' defaultValue='true' />
                  <div className='mb-4'>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      value={name}
                      onChange={(e) => onChange(e)}
                      autoComplete='name'
                      required
                      placeholder='Full Name'
                      className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='email-address'
                      name='email'
                      type='email'
                      value={email}
                      onChange={(e) => onChange(e)}
                      autoComplete='email'
                      required
                      placeholder='Email'
                      className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      value={password}
                      onChange={(e) => onChange(e)}
                      autoComplete='current-password'
                      required
                      placeholder='Password'
                      className='password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='password2'
                      name='password2'
                      type='password'
                      value={password2}
                      onChange={(e) => onChange(e)}
                      required
                      placeholder='Confirm Password'
                      className='password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='mobile'
                      name='mobile'
                      type='tel'
                      placeholder='Mobile Number'
                      value={mobile}
                      onChange={(e) => onChange(e)}
                      autoComplete='current-mobile'
                      required
                      className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <div className='mb-4'>
                    <input
                      id='company'
                      name='company'
                      type='text'
                      placeholder='Company Name'
                      value={company}
                      onChange={(e) => onChange(e)}
                      autoComplete='current-company'
                      required
                      className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
                    />
                  </div>
                  <button
                    type='submit'
                    className='register-button text-white py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider focus:outline-none'
                  >
                    Register
                  </button>
                </form>
                <p className='text-center'>
                  Already have an account?{' '}
                  <Link to='/login' className='login-link no-underline'>
                    Login
                  </Link>
                </p>
              </div>
            </div>

            {/* Right Area */}
            <div className='flex-grow ml-8 flex items-center justify-left'>
              {' '}
              {/* Added flex classes here */}
              <div className='rounded-lg p-8'>
                <h2 className='side-text-1'>Generating</h2>
                <h2 className='side-text-2'>Consumer Insights:</h2>
                <h2 className='side-text-3'>Through</h2>
                <h2 className='side-text-4'>Generative AI</h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Register.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  isOtpVerification: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  isOtpVerification: state.auth.isOtpVerification,
});

export default connect(mapStateToProps, { register })(Register);
