import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { logout } from '../../../actions/auth';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Image
import hamburger_icon from '../../../img/hamburger_icon.png';
import { toast } from 'react-toastify';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

const HamburgerNavBar = ({ auth: { user }, logout }) => {
  const [showManageProfile, setShowManageProfile] = useState(false);
  const navigate = useNavigate(); // <-- Initialize useNavigate hook

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.removeItem('isToastShown'); // Remove the 'isToastShown' item from local storage
    await logout();
    navigate('/login'); // <-- Navigate to the login page after logout
    toast.success('Logged out successfully');
  };

  if (user && !showManageProfile) {
    if (user.user_isSuperUser) {
      setShowManageProfile(true);
    }
  }

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-gray-900 focus:outline-0'>
          <img
            src={hamburger_icon}
            alt='hamburger-menu'
            area-hidden='true'
            className='w-5 h-4'
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/profile'
                  className='hover:bg-hover_4-10 text-gray-700 hover:text-gray-700  block px-4 py-2 text-sm hover:no-underline'
                >
                  <i class='fa-solid fa-user-pen mr-3'></i>
                  My Profile
                </Link>
              )}
            </Menu.Item>

            {showManageProfile ? (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to='/manage-profile'
                    className='hover:bg-hover_4-10 text-gray-700 hover:text-gray-700  block px-4 py-2 text-sm hover:no-underline'
                  >
                    <i class='fa-solid fa-user-gear mr-3'></i>
                    Manage User
                  </Link>
                )}
              </Menu.Item>
            ) : (
              <></>
            )}
          </div>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/login'
                  className='hover:bg-hover_4-10 text-secondary_3-10 hover:text-secondary_3-10  block px-4 py-2 text-sm hover:no-underline font-medium'
                  onClick={(e) => handleLogout(e)}
                >
                  <i class='fa-solid fa-arrow-right-from-bracket mr-3'></i>
                  Logout
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

HamburgerNavBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(HamburgerNavBar);
