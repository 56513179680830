import React, { useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

import { otpVerification } from '../../actions/auth';
import { connect } from 'react-redux';
import { clearUserRecords } from '../../actions/user';
import Loader from '../loader/Loader';

import PropTypes from 'prop-types';

// Image Imports
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
// import OtpFailedRegistration from '../layout/modals/OtpFailedRegistration';
import OtpSessionTimeOut from '../layout/modals/OtpSessionTimeOut';

// Images
import review_now_logo_color from '../../img/review_now_logo_color.png';
import background from '../../img/background.png';

//CSS
import '../../css/auth/otp.css';

let Otp = ({
  isAuthenticated,
  isOtpVerified,
  otpVerification,
  clearUserRecords,
}) => {
  const [otpValue, setOtpValue] = useState('');
  const inputRef = useRef();

  // Inside your Otp component
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(120); // 2 minutes in seconds
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const endTime = DateTime.local().plus({ minutes: 2 });
    const timer = setInterval(() => {
      const currentTime = DateTime.local();
      const diffSeconds = endTime.diff(currentTime, 'seconds').seconds;
      if (diffSeconds <= 0) {
        setIsTimerExpired(true);
        clearInterval(timer);
        clearUserRecords().then((res) => {
          console.log(res.data);
          toast.warn(res.data.msg);
          localStorage.removeItem('token');
        });
      } else {
        setRemainingTime(diffSeconds);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  const onInputChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digits
    setOtpValue(sanitizedValue);

    if (sanitizedValue.length === 4) {
      inputRef.current.blur(); // Blur the input field when 4 digits are entered
      console.log('OTP Success');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Combine OTP values and remove white spaces
    console.log('Entered OTP:', otpValue);
    setLoading(true);
    otpVerification(otpValue)
      .then((e) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    console.log('OTP Success');
  };

  // Redirect if OTP VERIFIED and isAuthenticated
  if (isOtpVerified) {
    toast.success('OTP Verified, Please Login');
    // Navigate to Login page
    return <Navigate to='/login' />;
  }

  console.log('auth', isAuthenticated);

  // if (!isTimerExpired) {
  const minutes = Math.floor(remainingTime / 60);
  const seconds = Math.floor(remainingTime % 60);
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`${background} min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link to='/about' className='about-us-navbar hover:no-underline'>
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow items-center justify-center'>
            <div className='text-center otp-main-content'>
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                className='mail-icon'
                style={{ fontSize: '67px' }}
              />
              <h2 className='verify-email'>Verify your email</h2>
              <p className='verify-email-secondary'>
                We have sent a verification code to your email.
                <br />
                Please enter it to verify your account.
              </p>
              <form onSubmit={(e) => onSubmit(e)} method='POST'>
                <div className='flex items-center justify-center mb-8'>
                  <input
                    value={otpValue}
                    onChange={onInputChange}
                    pattern='[0-9]{4}'
                    maxLength='4'
                    required
                    className='otp-box w-1/2 h-12 text-center rounded focus:outline-blue-600 mx-1'
                  />
                </div>
                <button
                  type='submit'
                  className='verify-email-button py-2 px-4 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10'
                >
                  Verify Email
                </button>
              </form>

              <div className='flex justify-center items-center'>
                <div>
                  Session Expires in{' '}
                  <span
                    className={`timer ${
                      formattedTime.includes('00:')
                        ? 'text-red-500'
                        : 'text-green-500 font-medium'
                    } px-1`}
                  >
                    {formattedTime}
                  </span>{' '}
                  mins
                </div>
              </div>
            </div>
          </div>
          {isTimerExpired ? <OtpSessionTimeOut /> : ''}
        </div>
      )}
    </>
  );
};

Otp.propTypes = {
  setAlert: PropTypes.func.isRequired,
  otpVerification: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // isOtpVerified: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isOtpVerified: state.auth.isOtpVerified,
});

export default connect(mapStateToProps, {
  otpVerification,
  clearUserRecords,
})(Otp);
