import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const Alert = ({ alerts }) =>
//   alerts !== null &&
//   alerts.length > 0 &&
//   alerts.map((alert) => (
//     <div
//       className={`alert alert-${alert.alertType} alert-dismissible fade show w-1/2`}
//       role='alert'
//     >
//       {alert.msg}
//       <button
//         type='button'
//         className='close'
//         data-dismiss='alert'
//         aria-label='Close'
//       >
//         <span aria-hidden='true'>&times;</span>
//       </button>
//     </div>
//   ));

const Alert = ({ alerts }) => {
  useEffect(() => {
    if (alerts && alerts.length > 0) {
      alerts.forEach((alert) => {
        switch (alert.alertType) {
          case 'success':
            toast.success(alert.msg);
            break;
          case 'error':
            toast.error(alert.msg);
            break;
          case 'warning':
            toast.warning(alert.msg);
            break;
          default:
            toast.info(alert.msg);
            break;
        }
      });
    }
  }, [alerts]);

  return <ToastContainer />;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
