import axios from 'axios';
import { setAlert } from './alert';
import {
  USER_LIST_SUCCESS,
  USER_LIST_FAILED,
  USER_DETAILS_SUCCESS,
} from './types';

// GET USERS LIST
export const getUsersList = (page_no) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  console.log('new page', page_no);

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/list-user?page_no=${page_no}`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }

    dispatch({
      type: USER_LIST_FAILED,
    });
  }
};

// GET USERS DeTAILS
export const getUserDetails = (user_id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  console.log('User_id', user_id);

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/review-now/api/user-profile?user_id=${user_id}`,
      config
    );

    // console.log('res from API', res);
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }

    dispatch({
      type: USER_LIST_FAILED,
    });
  }
};

// UPDATE USER SERVICE
export const updateUserService =
  (checkedValues, user_id, startDate, endDate) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const body = JSON.stringify({
        checkedValues,
        app_sDate: startDate,
        app_eDate: endDate,
      });
      console.log('.....', checkedValues, user_id);

      const res = await axios.patch(
        `${process.env.REACT_APP_API_URI}/review-now/api/update-app-dates?user_id=${user_id}`,
        body,
        config
      );

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, 'danger'));
        });
      }
    }
  };

// CLEAR USER RECORDS
export const clearUserRecords = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URI}/review-now/api/clear-record`,
      config
    );

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
  }
};
