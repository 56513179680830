import ClipLoader from 'react-spinners/ClipLoader';
import { connect } from 'react-redux';

const Loader = (loading) => {
  return (
    <div className='flex justify-center items-baseline w-full h-screen'>
      <div className='m-auto'>
        <ClipLoader color={'#1167B1'} loading={loading} size={50} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(Loader);
