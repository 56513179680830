import React from 'react';
import { Link } from 'react-router-dom';

const RevenueAnalytics = () => {
  return (
    <>
      <div className='mt-2 flex justify-center'>
        <Link to='/converse-ai-products' style={{ textDecoration: 'none' }}>
          <i class='fa-solid fa-circle-arrow-left'></i> Select Services
        </Link>
      </div>
      <div className='flex justify-center my-2 mx-4'>
        <iframe
          title='Sales revenue_Inference Analytics22Mar'
          width='1080'
          height='480'
          src='https://app.powerbi.com/view?r=eyJrIjoiOGE5ODQxMjctNGE3Yy00ZjMyLWI5OTQtMjhjY2VkOTgwZTIzIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9&embedImagePlaceholder=true&pageName=ReportSection6a0564a015eb0595ab3a'
          frameborder='0'
          allowFullScreen='true'
        ></iframe>
      </div>
    </>
  );
};

export default RevenueAnalytics;
