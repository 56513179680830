import { LockClosedIcon } from '@heroicons/react/solid';
import inferenceLogo from '../../img/inference.png';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { passwordResetLink, clickForgotPassword } from '../../actions/auth';
import Loader from '../loader/Loader';

import '../../css/forgotPassword.css';

//Import Images
import logo_sm_color from '../../img/ic_logo_color.png';
import check_mail_bg_1 from '../../img/check_mail_bg_1.png';
import forgot_password_bg_1 from '../../img/forgot_password_bg_1.png';
import mail_box_icon from '../../img/mail_box_icon.png';
import review_now_logo_color from '../../img/review_now_logo_color.png';
import bg_4 from '../../img/bg_4.png';
import check_mail_vector from '../../img/check_mail_vector.png';
import forgorPassVector from '../../img/forgorPassVector.png';
import check_mail_icon from '../../img/check_mail_icon.png';

let Email_forgetPassword = ({
  passwordResetLink,
  isAuthenticated,
  isResetLinkSent,
  setAlert,
  msg,
  clickForgotPassword,
}) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  // Destructure formData
  const { email } = formData;

  const navigate = useNavigate();

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    passwordResetLink(email)
      .then((e) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    console.log('Success');
  };

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();

    console.log('click on event');

    // Call action here
    await clickForgotPassword();
    console.log('below action function');

    //navigate to link page
    navigate('/email-forget-password');
    console.log('below navigate');
  };

  // Redirect if logged in
  // if (isAuthenticated) {
  //   return <Navigate to='/dashboard' />;
  // }

  const loginPage = (e) => {
    e.preventDefault();
    <Navigate to='/login' />;
  };

  const sendEmail = (
    <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
      <div className='forgot-pass-div-card flex flex-col items-center justify-center bg-white rounded-lg p-16'>
        <h2 className='forgot-pass-heading'>Forgot Password</h2>
        <form className='w-full' onSubmit={(e) => onSubmit(e)} method='POST'>
          <input type='hidden' name='remember' defaultValue='true' />

          <div className='mb-4'>
            <p className='forgot-pass-note text-center'>
              Enter your registered email address below to receive a link to
              reset your password
            </p>

            <input type='hidden' name='remember' defaultValue='true' />
            <input
              id='email-address'
              name='email'
              type='email'
              value={email}
              onChange={(e) => onChange(e)}
              autoComplete='email'
              required
              placeholder='Email'
              className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative'
            />
          </div>

          <button
            type='submit'
            className='register-button text-white py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider focus:outline-none'
          >
            Send Link
          </button>
        </form>
        <p className='text-center items-center'>
          <Link to='/Login' className='back-to-login-link no-underline'>
            <i class='fa-solid fa-chevron-left mr-2 text-xs'></i>Back to Login
          </Link>
        </p>
      </div>
    </div>
  );

  const checkEmail = (
    <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
      <div className='check-mail-div-card flex flex-col items-center justify-center bg-white rounded-lg px-8 pt-8 pb-1'>
        <div className='mb-4 justify-center '>
          <img
            src={check_mail_icon}
            alt='checkMailIcon'
            className='check-mail-icon'
          />
        </div>
        <h2 className='forgot-pass-heading'>Check your email</h2>
        <form className='w-full' onSubmit={(e) => onSubmit(e)} method='POST'>
          <input type='hidden' name='remember' defaultValue='true' />

          <div className='mb-5'>
            <p className='forgot-pass-note text-justify'>
              A password reset email has been sent to your registered email
              address. Kindly refer to the email instructions to recover your
              password.
            </p>
          </div>
        </form>

        <p className='text-center items-center text-sm font-medium mb-4'>
          Didn't receive the link?
          <Link
            to='/email-forget-password'
            className='back-to-login-link no-underline ml-1'
            onClick={(e) => handleClickForgotPassword(e)}
          >
            Resend Link
          </Link>
        </p>

        <p className='text-center items-center text-xs'>
          Didn't receive the email? Check your spam folder
        </p>
      </div>
    </div>
  );

  const mail_spam_text = (
    <div className='check-email-spam-text absolute bottom-2 left-0 text-center w-full'>
      <p>Didn't receive the email? Check your spam folder.</p>
    </div>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/login'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow'>
            {!isResetLinkSent ? sendEmail : checkEmail}
            {/* {!isResetLinkSent ? <></> : mail_spam_text} */}

            {/* Left Area */}

            {/* Right Area */}
            <div className='flex-grow ml-8 flex items-center justify-left'>
              {' '}
              {/* Added flex classes here */}
              <div className='rounded-lg p-8'>
                {/* <img
           src={!isResetLinkSent ? forgorPassVector : check_mail_vector} 
             alt='otp-verification' 
             className={ 
              (isResetLinkSent ? 'otp_bg_1' : 'otp_bg_2') +
              (!isResetLinkSent ? ' forgot_password_bg_1' : ' check_mail_bg_1')
            }
          /> */}
                <img
                  src={!isResetLinkSent ? forgorPassVector : check_mail_vector}
                  alt='forgotPassMail'
                  className={
                    !isResetLinkSent
                      ? ' forgorPassVector_bg'
                      : ' check_mail_vector_bg'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Email_forgetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  passwordResetLink: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  isResetLinkSent: PropTypes.bool.isRequired,
  // msg: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isResetLinkSent: state.auth.isResetLinkSent,
  msg: state.auth.msg,
});

export default connect(mapStateToProps, {
  setAlert,
  passwordResetLink,
  clickForgotPassword,
})(Email_forgetPassword);
