import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import Form from './components/Form';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Otp from './components/auth/Otp';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import EmailForgetPassword from './components/layout/Email_forgetPassword';
import ProductsList from './components/dashboard/ProductsList';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import '../src/App.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import ResetPassword from './components/layout/ResetPassword';
import Templates from './components/dashboard/Templates';
import Reports from './components/dashboard/Reports';
import ClipLoader from 'react-spinners/ClipLoader';

import { useState } from 'react';
import Loader from './components/loader/Loader';
import SentimentAnalysis from './components/dashboard/SentimentAnalysis';
import DocSummarisation from './components/dashboard/DocSummarisation';
import AiProducts from './components/layout/AiProducts';
import HrIframe from './components/layout/iFrames/HrIframe';
import RevenueAnalytics from './components/layout/iFrames/RevenueAnalytics';
import Profile from './components/layout/Profile';
import ManageProfile from './components/user/ManageProfile';
import Page404 from './components/layout/Page404';
import AnalysisType from './components/dashboard/AnalysisType';
import StaticProdList from './components/dashboard/StaticProdList';
import Embeddedreports from './components/dashboard/Embeddedreports';
import About from './components/layout/otherComponents/About';
import ContactUs from './components/layout/otherComponents/ContactUs';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    store.dispatch(loadUser());
    setLoading(false);
  }, []);

  return (
    <Provider store={store}>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Fragment>
            {/* <Navbar></Navbar> */}
            <section className='container pr-0 pl-0'>
              <Alert></Alert>
              <Routes>
                <Route exact path='/' element={<Login />} />
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/otp-verification' element={<Otp />} />
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route
                  exact
                  path='/reset-password'
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path='/email-forget-password'
                  element={<EmailForgetPassword />}
                />
                <Route exact path='/products-list' element={<ProductsList />} />
                <Route exact path='/templates' element={<Templates />} />
                <Route exact path='/reports-power-bi' element={<Reports />} />
                <Route
                  exact
                  path='/sentiment-analysis'
                  element={<SentimentAnalysis />}
                />
                <Route
                  exact
                  path='/doc-summarisation'
                  element={<DocSummarisation />}
                />
                <Route
                  exact
                  path='/converse-ai-products'
                  element={<AiProducts />}
                />
                <Route exact path='/hr-analytics' element={<HrIframe />} />
                <Route
                  exact
                  path='/revenue-analytics'
                  element={<RevenueAnalytics />}
                />
                <Route exact path='/profile' element={<Profile />} />
                <Route
                  exact
                  path='/manage-profile'
                  element={<ManageProfile />}
                />
                <Route exact path='*' element={<Page404 />} />
                <Route exact path='/analysis-type' element={<AnalysisType />} />
                <Route exact path='/prod-list' element={<StaticProdList />} />
                <Route exact path='/embed' element={<Embeddedreports />} />
                <Route exact path='/about-us' element={<About />} />
                <Route exact path='/contact-us' element={<ContactUs />} />
              </Routes>
            </section>
          </Fragment>
        </Router>
      )}
    </Provider>
  );
};

export default App;
