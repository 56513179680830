import React from 'react';
import Rating from '../Rating';

// import dummy_prod from '../../../img/dummy_prod.png';

const ProductCardSearchPage = ({
  price,
  brand,
  prod_name,
  prod_image,
  bestSellerRank,
  sentimentScore,
  startRating,
  verifiedReviews,
  setSelectedProduct,
  setShowModal,
  category,
  industry,
}) => {
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
    console.log('working');
  };

  return (
    <div
      className='w-44 mx-1 shadow-md cursor-pointer'
      onClick={() =>
        handleProductClick({
          price,
          brand,
          prod_name,
          prod_image,
          bestSellerRank,
          sentimentScore,
          startRating,
          verifiedReviews,
          category,
          industry,
        })
      }
    >
      <div
        className='relative h-56 w-44 bg-white shadow-lg rounded-lg overflow-hidden'
        style={{ marginBottom: '-96px' }}
      >
        <img
          src={prod_image}
          alt='Product'
          className='h-36 w-full object-cover'
          style={{ objectFit: 'contain' }}
        />
        <div className='p-2'>
          <h3 className='card-prod-name'>
            {prod_name.split(' ').slice(0, 5).join(' ')}
          </h3>
          <div className='absolute bottom-0 left-0 right-0 py-1.5 card-prod-price-background'>
            <p className='text-gray-100 font-medium text-sm'>
              <Rating value={startRating}></Rating>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSearchPage;
