import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../loader/Loader';
import { LockClosedIcon } from '@heroicons/react/solid';

import '../../css/accessDenied.css';

// import Images
import access_denied_bg_1 from '../../img/access_denied_bg_1.png';
import lock_icon from '../../img/lock_icon.png';
import logo_sm_color from '../../img/ic_logo_color.png';
import review_now_logo_color from '../../img/review_now_logo_color.png';
import accessDeniedVector from '../../img/accessDeniedVector.png';

const SignInAccess = (props) => {
  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  return (
    // Show Login and register Buttons
    <>
      {props.loading ? (
        <Loader />
      ) : (
        // <div
        //   className='grid grid-cols-2 grid-rows-1 h-screen'
        //   style={{
        //     gridTemplateColumns: '60% 40%',
        //   }}
        // >
        //   <div className='flex flex-col items-center  bg-background_3-10'>
        //     <img
        //       src={logo_sm_color}
        //       alt='logo'
        //       className='h-12 w-12 mb-20 self-start ml-20 mt-10'
        //     />
        //     <img
        //       src={access_denied_bg_1}
        //       alt='access-denied_background'
        //       className='access-denied_background self-center'
        //     />
        //   </div>
        //   <div className='flex flex-col justify-center items-center bg-background_3-10'>
        //     <div className='container max-w-xs mx-auto'>
        //       <div className='flex justify-center'>
        //         <img
        //           src={lock_icon}
        //           alt='mail_box_icon'
        //           className='h-12 w-12 object-cover mb-6'
        //         />
        //       </div>
        //       <h3 className='access-denied-h3'>We are sorry...</h3>
        //       <div className='access-denied-secondary-text'>
        //         <p className='text-justify'>
        //           We regret to inform you that the dashboard is currently
        //           inaccessible without a valid account. kindly log in with your
        //           existing credentials or create a new account to access
        //           dashboard.
        //         </p>
        //       </div>
        //       <div>
        //         <Link to='/login' className=' hover:no-underline'>
        //           <button className='access-denied-login-button group relative flex justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider'>
        //             Login
        //           </button>
        //         </Link>
        //         <div className='mt-4'>
        //           <Link to='/register' className=' hover:no-underline'>
        //             <button className='access-denied-register-button group relative flex justify-center border border-transparent  hover:bg-hover_1-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider'>
        //               Register
        //             </button>
        //           </Link>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        <div className={`min-h-screen p-4 flex flex-col`}>
          {/* Top Section */}
          <div className='flex justify-between items-center mb-8'>
            <div className='flex items-center'>
              <img
                src={review_now_logo_color}
                alt='Logo'
                className='navbar-logo'
              />
              <Link
                to='/analysis-type'
                className='ml-2 navbar-logo-text hover:no-underline'
              >
                Review Now
              </Link>
            </div>
            <div className='space-x-4'>
              <Link
                to='/about-us'
                className='about-us-navbar hover:no-underline'
              >
                About us
              </Link>
              <Link
                to='/contact-us'
                className='contact-us-navbar hover:no-underline'
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Main Content */}
          <div className='flex flex-grow'>
            {/* Left Area */}
            <div className='w-2/5 flex-shrink-0 flex items-center justify-center'>
              <div className='w-4/5 flex flex-col items-center justify-center p-12'>
                <h2 className='access-denied-h3'>We are sorry...</h2>
                <p className='access-denied-secondary-text text-center'>
                  We regret to inform you that the application is currently
                  inaccessible without a valid account. kindly log in with your
                  existing credentials or create a new account to access
                  application.
                </p>
                <div className='w-full'>
                  <Link to='/login' className=' hover:no-underline'>
                    <button className='access-denied-login-button group relative flex justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider'>
                      Login
                    </button>
                  </Link>
                  <Link to='/register' className=' hover:no-underline'>
                    <button className='access-denied-register-button group relative flex justify-center border border-transparent  hover:bg-hover_1-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider'>
                      Register
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* Right Area */}
            <div className='flex-grow ml-8 flex items-center justify-left'>
              {' '}
              {/* Added flex classes here */}
              <div className='rounded-lg p-8'>
                <img
                  src={accessDeniedVector}
                  alt='createNewPassVector'
                  className='access-denied-vector'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignInAccess;
