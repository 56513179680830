import { USER_LIST_SUCCESS, USER_LIST_FAILED } from '../actions/types';

const initialState = {
  isUserListLoaded: false,
};

export default function users(state = initialState, action) {
  // destructure action
  const { type, payload } = action;

  switch (type) {
    case USER_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        isUserListLoaded: true,
      };
    case USER_LIST_FAILED:
      return {
        ...state,
        ...payload,
        isUserListLoaded: false,
      };

    default:
      return state;
  }
}
