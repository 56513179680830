import React from 'react';
import { Link } from 'react-router-dom';

const HrIframe = () => {
  return (
    <>
      <div className='mt-2 flex justify-center'>
        <Link to='/converse-ai-products' style={{ textDecoration: 'none' }}>
          <i class='fa-solid fa-circle-arrow-left'></i> Select Services
        </Link>
      </div>
      <div className='flex justify-center my-2 mx-4'>
        <iframe
          title='HR Use Case V3_updated25'
          width='1080'
          height='480'
          src='https://app.powerbi.com/view?r=eyJrIjoiN2NlODNhZmUtYjQ0Ny00NDRlLTk2MzYtYWQwYzM0ZjA3MjZhIiwidCI6ImYxNGE5NzNlLTFkNTktNDBlMy1hZjBjLTFjOWViYzgyMjFhZCJ9&pageName=ReportSection40a981231ce582c506ca'
          frameborder='0'
          allowFullScreen='true'
        ></iframe>
      </div>
    </>
  );
};

export default HrIframe;
