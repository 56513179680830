export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const OTP_VERIFIED = 'OTP_VERIFIED';
export const OTP_FAILED = 'OTP_FAILED';
export const PASSWORD_RESET_LINK_SUCCESS = 'PASSWORD_RESET_LINK_SUCCESS';
export const PASSWORD_RESET_LINK_FAILED = 'PASSWORD_RESET_LINK_FAILED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const VERIFY_RESET_LINK_SUCCESS = 'VERIFY_RESET_LINK_SUCCESS';
export const VERIFY_RESET_LINK_FAILED = 'VERIFY_RESET_LINK_FAILED';
export const LOADER = 'LOADER';

export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED';

export const SEARCH_VALUE = 'SEARCH_VALUE';

export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILED = 'USER_LIST_FAILED';

export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILED = 'USER_DETAILS_FAILED';

export const VISIT_FORGOT_PASSWORD_LINK = 'VISIT_FORGOT_PASSWORD_LINK';
export const USER_OTP_VERIFICATION = 'USER_OTP_VERIFICATION';
